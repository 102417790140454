import { createBrowserRouter } from "react-router-dom";
import Home from "../Pages/Home";

import SignUp from "../Components/Connection/SignUp";
import SignIn from "../Components/Connection/SignIn";
import ProtectedRoute from "../Services/ProtectedRoute";
import Products from "../Pages/Products";
import Blog from "../Pages/Blog";
import Post from "../Components/Blog/Post";
import Subscription from "../Pages/Subscription";
import NewSubscription from "../Components/Subscriptions/NewSubscription";
import ValidateSubscription from "../Components/Subscriptions/ValidateSubscription";
import Share from "../Pages/Share";
import ShareCategory from "../Components/Shares/ShareCategory";
import ShareType from "../Components/Shares/ShareType";
import ShareDetails from "../Components/Shares/ShareDetails";
import ShareResume from "../Components/Shares/ShareResume";
import UnknownNewSubscription from "../Components/Subscriptions/UnknownNewSubscription";
import FailedSubscription from "../Components/Subscriptions/FailedSubscription";
import ReqResetPassWord from "../Components/Connection/ReqResetPassWord";
import ResetPassWord from "../Components/Connection/ResetPassWord";
import { decryptData } from "../Services/EncryptData";
import ReNewal from "../Components/Subscriptions/ReNewal";
import CGU from "../Pages/HelpCenter/CGU";
import RefundPolicy from "../Pages/HelpCenter/RefundPolicy";
import NotFoundPage from "../Pages/NotFoundPage";
import Cart from "../Pages/Cart";
import Category from "../Pages/Category";
import Account from "../Pages/Account";
import Login from "../Components/Connection/login";
import CreateAccount from "../Components/Connection/CreateAccount";
import ForgotPassword from "../Components/Connection/ForgotPassword";
import OtpVerification from "../Components/Connection/otpVerification";
import InviteFriend from "../Components/Referral/InviteFriend";
import CreateCompteByReferral from "../Components/Referral/CreateCompteByReferral";

const token = localStorage.getItem("token");
// const token = token_ === null ? null : decryptData(token_)

const router = createBrowserRouter([
    // {path:"" , element: <NotFoundPage /> },
    { path: "/", element: <Home /> },

    //Connections routes
    { path: "/signin", element: <Login /> },
    { path: "/signup", element: <CreateAccount /> },
    { path: "/resetpassword", element: <ForgotPassword /> },
    { path: "/reset-password/:resetToken", element: <ResetPassWord /> },
    { path: "/verify-email", element: <OtpVerification /> },

    //Products routes
    // { path: "/products", element: <Products /> },
    { path: "/products", element: <Products /> },
    { path: "/products/products-category/:categoryPath", element: <Category /> },


    //Subscriptions routes
    { path: "/subscription/:id", element: <Subscription /> },
    {
        path: "/new-subscription/:id", element:
            <ProtectedRoute isAuthenticated={token}>
                <NewSubscription type="New Subscription" />
            </ProtectedRoute>
    },
    { path: "/unknown-new-subscription/:id", element: <UnknownNewSubscription /> },
    {
        path: "/renewal-subscription/:ref", element:

            <ProtectedRoute isAuthenticated={token}>
                {/* <ReNewal /> */}
                <NewSubscription type="Renewal" />
            </ProtectedRoute>
    },
    { path: "/validate-subscription", element: <ValidateSubscription /> },
    { path: "/failed-subscription", element: <FailedSubscription /> },
    //Blog app routes
    { path: "/blog", element: <Blog /> },
    { path: "/blog/post/:id", element: <Post /> },


    // Help center routes

    {
        path: "/help-center/CGU", element: <CGU />
    },
    {
        path: "/help-center/refund-policy", element: <RefundPolicy />
    },

    // Account
    {
        path: "/account/*", element:
            <ProtectedRoute isAuthenticated={token}>
                <Account />
            </ProtectedRoute>
    },
    {
        path: "/refer-a-friend", element:
            // <ProtectedRoute isAuthenticated={token}>
            //     <InviteFriend />
            // </ProtectedRoute>
            <InviteFriend />

    },
    {
        path: "/account/create-compte-by-referral", element:
            <CreateCompteByReferral />
    },

    // {
    //     path: "/account/user-subscriptions", element:
    //         <ProtectedRoute isAuthenticated={token}>
    //             <UserSubscription />
    //         </ProtectedRoute>
    // },
    // {
    //     path: "/account/edit-user/:id", element:
    //         <ProtectedRoute isAuthenticated={token}>
    //             <EditInfos />
    //         </ProtectedRoute>
    // },

    // Share
    {
        path: "/share", element:
            <ProtectedRoute isAuthenticated={token}>
                <Share />
            </ProtectedRoute>
    },
    {
        path: "/share/share-category", element:
            <ProtectedRoute isAuthenticated={token}>
                <ShareCategory />
            </ProtectedRoute>
    },
    {
        path: "/share/share-type", element:
            <ProtectedRoute isAuthenticated={token}>
                <ShareType />
            </ProtectedRoute>
    },
    {
        path: "/share/share-details", element:
            <ProtectedRoute isAuthenticated={token}>
                <ShareDetails />
            </ProtectedRoute>
    },
    {
        path: "/share/share-resume", element:
            <ProtectedRoute isAuthenticated={token}>
                <ShareResume />
            </ProtectedRoute>
    },

    //cart
    {
        path: "/cart", element:
            <Cart />
    },



]);

export default router;
