import { useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import Instance, { googleAuth } from "../../Services/Instance";
import config from "../../Services/config";
import { encryptData } from "../../Services/EncryptData";
import iconGoogle from "../../Assets/Imgs/icons/icon-google.svg";
import "../../Assets/Styles/Connexion.css";

const LoginGoogle = () => {
    const [userNumberPhone, setUserNumberPhone] = useState("");
    const [openPopup, setOpenPopup] = useState(false);
    const [user, setUser] = useState("");

    const responseGoogle = async (authResult) => {
        try {
            if (authResult["code"]) {
                const result = await googleAuth(authResult.code);
                const { email } = result.data.user;
                const token = result.data.token;

                // Enregistrer le token
                localStorage.setItem("token", token);
                setUser(result.data.user);

                // Vérifier si l'utilisateur existe dans la BDD
                const userResponse = await Instance.get(`${config.api_url}/show-user-by-email/${email}`);
                const userFromDB = userResponse.data.user;

                if (!userFromDB.numberPhone) {
                    setOpenPopup(true); // Afficher le popup si le téléphone n'est pas renseigné
                } else {
                    // Stocker l'utilisateur si le numéro est déjà présent
                    const encryptUser = encryptData(userFromDB);
                    localStorage.setItem("user", encryptUser);
                    window.location = "/";

                }
            } else {
                throw new Error(authResult);
            }
        } catch (e) {
            console.log("❌ Erreur lors de la connexion Google :", e);
        }
    };

    const googleLogin = useGoogleLogin({
        onSuccess: responseGoogle,
        onError: responseGoogle,
        flow: "auth-code",
    });

    // ✅ Fonction pour sauvegarder le numéro de téléphone
    const handleSavePhoneNumber = async () => {
        if (userNumberPhone.length < 8) {
            alert("Veuillez entrer un numéro valide !");
            return;
        }

        try {
            // Mise à jour du numéro de téléphone en base de données

            await Instance.put(`${config.api_url}/update-user/${user._id}`,
                {
                    numberPhone: userNumberPhone
                });


            // Fermer le popup et enregistrer l'utilisateur
            setOpenPopup(false);
            const encryptUser = encryptData(user);
            localStorage.setItem("user", encryptUser);
            window.location = "/";

        } catch (error) {
            console.error("❌ Erreur lors de la mise à jour du numéro de téléphone :", error);
        }
    };

    return (
        <div className="google-login">
            <button onClick={googleLogin}>
                <img src={iconGoogle} alt="icon-google" /> Se Connecter avec Google
            </button>

            {openPopup && (
                <div className="popup">
                    <div className="popup-content">
                        <h3>Renseignez votre numéro de téléphone</h3>
                        <input
                            type="text"
                            placeholder="Numéro de téléphone"
                            value={userNumberPhone}
                            onChange={(e) => setUserNumberPhone(e.target.value)}
                        />
                        <button onClick={handleSavePhoneNumber}>Valider</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LoginGoogle;
