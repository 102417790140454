import React, { useEffect, useState } from 'react'
import NavBar from '../../Layouts/NavBar'
import "../../Assets/Styles/Compte.css"
import { Link, useParams } from 'react-router-dom'
import Instance from '../../Services/Instance'
import config from "../../Services/config.js"

function UserSubscription() {

    const [userSubscriptions, setUserSubscriptions] = useState([])
    const { id } = useParams();

    const fetchData = async () => {
        try {
            const result = await Instance.get(`${config.api_url}/user-subscriptions`)
            // console.log(result.data.subscriptions)
            setUserSubscriptions(result.data.subscriptions)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchData();
    }, [])

    const newDate = new Date();
    const _date = newDate.toLocaleDateString('fr-FR')

    function formatterDate(date) {
        const newDate = new Date(date);
        const _date = newDate.toLocaleDateString('fr-FR')
        return _date
    }



    // Filtrer les abonnements avec le statut "Validate"
    const validateSubscriptions = userSubscriptions.filter(subscription => subscription.subscriptionStatus === 'Validate');

    // Inverser l'ordre du tableau
    const reversedValidateSubscriptions = validateSubscriptions.reverse();
    console.log(reversedValidateSubscriptions, "++++");

    return (
        <div className='user-subscription'>
            <div className='user-subscription-content'>

                <div className='user-subscription-title '>
                    <div className='title-content'>
                        <h1>Abonnements Rejoins</h1>
                        <p> {_date} </p>
                    </div>

                </div>

                <div className='user-subscription-title layout-content'>
                    <div className='title-content'>
                        <h1>Rejoignez un co-abonnement</h1>
                        <p>Souscrivez à un abonnement et bénéficiez d'économies allant jusqu'à 70% chaque mois.</p>
                    </div>
                    <div className='btn-submit style-line'>
                        {/* <div className="vertical-line"></div> */}
                        <Link to="/products" className='link-submit' >Abonner</Link>
                    </div>
                </div>

                <div className='order-items'>
                    {
                        reversedValidateSubscriptions.map((subscription, index) => (
                            <div key={index} className='suggestion-subscription layout-content'>
                                <div className='first-content style-line'>
                                    <img src={`${config.img_url}/${subscription.productImage}`} alt="img-subscription" />
                                    {/* <div className="vertical-line"></div> */}
                                </div>
                                <div className='second-content'>
                                    <h4> {subscription.subscriptionName} </h4>

                                    {subscription.productTypeSmart && subscription.productTypeSmart === "Smart TV" && <div>
                                        {/* <p> Type d'abonnement netflix:  </p> */}
                                        <p> {subscription.productTypeSmart}  </p>
                                    </div>}
                                    <div className='second-content-items' >
                                        <p> N° Commande:  </p>
                                        <p> {subscription.subscriptionRef}  </p>
                                    </div>
                                    <div className='second-content-items'>
                                        <p> Date:  </p>
                                        <p>{formatterDate(subscription.subscriptionDate)}</p>
                                    </div>
                                    {subscription?.subscriptionLicense ?
                                        (<div className='second-content-key'>
                                            {/* <p> Détails de l'abonnement:  </p> */}
                                            <span>{(subscription.subscriptionLicense.key)}</span>
                                        </div>) :
                                        (<div className='second-content-key'>
                                            {/* <p> Détails de l'abonnement:  </p> */}
                                            <span>{(subscription.subscriptionKey)}</span>
                                        </div>)
                                    }
                                </div>
                                <div className='three-content'>

                                    {
                                        subscription && (subscription.CategoryName.includes("Cartes Cadeaux") || subscription.CategoryName === "Gaming") ?
                                            null
                                            :
                                            (<div className='btn-submit style-line'>
                                                {/* <div className="vertical-line"></div> */}
                                                <Link to={`/renewal-subscription/${subscription.subscriptionRef}`} className='link-submit' >Renouveler</Link>
                                            </div>)
                                    }
                                </div>
                            </div>

                        ))
                    }
                </div>
            </div>

        </div>
    )
}

export default UserSubscription