/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import NavBar from '../../Layouts/NavBar';
import config from "../../Services/config.js";
import Instance from '../../Services/Instance';
import { useParams } from "react-router-dom";
import { Link } from 'react-router-dom';
import "../../Assets/Styles/Subscriptions.css";
import levels from "../../Assets/Imgs/icons/icon-levels-subscriptions.svg";
import Footer from '../../Layouts/Footer';
import arrowIcon from "../../Assets/Imgs/icons/right-arrow-circular-symbol-svgrepo-com.svg";
import Modal from 'react-modal';
import iconClose from "../../Assets/Imgs/icons/icon-close.svg"
import iconSignin from "../../Assets/Imgs/icons/icon-seconnecter.svg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGift, faTicket } from '@fortawesome/free-solid-svg-icons';
import { decryptData } from '../../Services/EncryptData.js';
import { toast } from 'react-toastify';


// Définissez l'élément d'accessibilité pour le modal
Modal.setAppElement('#root');

function UnknownNewSubscription() {
    const [error, setError] = useState("");
    const [reNewal, setReNewal] = useState(false);
    const [product, setProduct] = useState([]);
    const [quantity, setQuantity] = useState(1);
    const [paymentInProgress, setPaymentInProgress] = useState(false); // État pour suivre l'état de la demande de paiement
    const [newSubscription, setNewSubscription] = useState({
        productTypeSmart: "",
        subscriptionQuantity: "",
        subPoints: "",
        smartTvPrice: "",
        userFirstName: "",
        userLastName: "",
        userEmail: "",
        userNumberPhone: "",
        userCountry: "",
        confirmEmail: ""
    });
    const [codePromo, setCodePromo] = useState(false)
    const [applyDiscountedPricePoints, setApplyDiscountedPricePoints] = useState(false)

    const [applyCodePromo, setApplyCodePromo] = useState(false);
    const [pointsApplied, setPointsApplied] = useState(0);

    const [discountedPrice, setDiscountedPrice] = useState(0);

    const { id } = useParams();

    // Récupération de l'utilisateur depuis le stockage local
    const user__ = localStorage.getItem("user")
    const user = user__ === null ? null : decryptData(user__)
    //Add a code promo
    const ActiveDiscount = (type) => {
        if (type === "promo") {
            setApplyCodePromo((prevState) => !prevState);
        } else if (type === "points") {
            if (!user) {
                alert("Vous devez vous connectez pour appliquer vos points")
                return;
            }
            setApplyDiscountedPricePoints((prevState) => !prevState);
        }
    };

    let newPromoValue;
    const handleChangeValue = (e) => {
        newPromoValue = e.target.value;
    }

    const handleApplyPromo = (e) => {
        if (product && product.promo.InPromo && (product.promo.codePromo === newPromoValue)) {
            setCodePromo(newPromoValue)
            toast.success('😁 Code Promo Appliqué avec succès !!!');
        } else {
            toast.error('🙄 Code Promo Invalide !!!');
        }
    }


    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewSubscription({ ...newSubscription, [name]: value });
    };

    // Recup product
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await Instance.get(`${config.api_url}/show-product/${id}`);
                setProduct(result.data.product);
            } catch (error) {
                setError(error);
                console.log(error);
            }
        };

        window.scrollTo(0, 0);
        fetchData();
    }, [id]);


    // Incrémentation de la quantité
    const increment = () => {
        setQuantity(quantity + 1);
    };

    // Décrémentation de la quantité
    const decrement = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    };

    // Recupérer le prix du smart tv 
    const smarTvPrice = localStorage.getItem('smartTvPrice');
    let netflixType = localStorage.getItem("netflixType")

    let optionSub = "";

    let priceSub = smarTvPrice ? smarTvPrice : product.productPrice;

    if (netflixType) {
        optionSub = `- ${netflixType}`
    } else if (!netflixType && product.productName && product.productName.toLocaleLowerCase().includes("netflix") && !product.productName.includes("NETFLIX Pack Family") && !product.productName.includes("Netflix Duo")) {
        optionSub = "- Phone/Pc"
    }
    // console.log(smarTvPrice);

    const { userFirstName, userLastName, userEmail, confirmEmail, userNumberPhone, userCountry } = newSubscription;
    const winPoints = () => {
        return Math.ceil((quantity * priceSub) / 40)
    }

    const handleSubscriptionRequest = async (e) => {

        e.preventDefault();
        if (paymentInProgress) return; // Si la demande de paiement est déjà en cours, ne rien faire


        try {
            const orderRequestSubscription = {
                productId: product._id,
                subscriptionQuantity: quantity,
                productTypeSmart: optionSub,
                subPoints: [winPoints(), pointsApplied],
                codePromo: codePromo,
                userFirstName: userFirstName,
                userLastName: userLastName,
                userEmail: userEmail,
                userNumberPhone: userNumberPhone,
                userCountry: userCountry || 'Senegal',
            };

            const formattedData = { subscriptions: JSON.stringify([orderRequestSubscription]) };
            console.log("✅ Données formatées :", formattedData);

            if (!window.PayTech) {
                console.error("❌ PayTech n'est pas défini !");
                return;
            }

            new PayTech(formattedData)
                .withOption({
                    requestTokenUrl: `${config.api_url}/new-subscription`,
                    method: 'POST',
                    headers: { Accept: "application/json" },
                    prensentationMode: PayTech.OPEN_IN_POPUP,
                    willGetToken: function () {
                        console.log("📌 willGetToken exécuté");
                    },
                    didGetToken: function (token, redirectUrl) {
                        console.log("✅ Token reçu :");
                    },
                    didReceiveError: function (error) {
                        console.error("❌ Erreur reçue :", error);
                    },
                    didReceiveNonSuccessResponse: function (jsonResponse) {
                        console.warn("⚠️ Réponse non réussie :");
                    }
                })
                .send();

            console.log("📌 PayTech appelé");

        } catch (error) {
            console.error("❌ Erreur lors de la demande d'abonnement :", error);
        } finally {
            console.log("🔄 Réinitialisation de l'état");
            setPaymentInProgress(false);
        }
    };



    const handleReNewal = () => { setReNewal(!reNewal) };
    // console.log(product);
    // Fonction pour mélanger le tableau de manière aléatoire
    // function shuffleArray(array) {
    //     return array.sort(() => Math.random() - 0.5);
    // }

    return (
        <div className='subscription'>
            <NavBar />

            <div className='subscription-content-layout subscription-levels'>
                <img src={levels} alt="delivery-img" />
            </div>
            <div className='sous-navbar ' id="sous-navbar-sub">
                <Link to="/" className='link-sous-navbar'> Home </Link>
                <span>/</span>
                <Link to="/products" className='link-sous-navbar'> Abonnements </Link>
                <span>/</span>
                <Link to={`/subscription/${product._id}`} className="link-sous-navbar" > Détails</Link>
                <span>/</span>
                <Link to={`/subscription/${product._id}`} className="link-sous-navbar" id='activeLink'> Informations </Link>
            </div>
            <div className='subscription-content-unknownSub'>
                <div className='subscription-content-layout content-left-unknownSub'>
                    {
                        !reNewal &&
                        <form>
                            <div className='options-subscription'>
                                <div type="button" onClick={handleReNewal} className='options-subscription-button'>
                                    <span>Souhaitez-vous faire <br /> un renouvellement ?</span>
                                    <img className='img-arrow' src={arrowIcon} alt="arrow" />
                                </div>
                                <Link to="/signin" className=''>Vous avez déjà un compte? Se connecter</Link>
                            </div>
                            <div className='elements-from'>
                                <div>
                                    <label>Prénom</label>
                                    <input
                                        name="userFirstName"
                                        value={userFirstName}
                                        type="text"
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div>
                                    <label>Nom</label>
                                    <input
                                        name="userLastName"
                                        value={userLastName}
                                        onChange={handleChange}
                                        type="text"
                                        required
                                    />
                                </div>
                                <div>
                                    <label>Pays</label>
                                    <input
                                        name="userCountry"
                                        value={userCountry}
                                        type="text"
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className='elements-from'>

                                <div>
                                    <label>Téléphone (Whatsapp)</label>
                                    <input
                                        name="userNumberPhone"
                                        value={userNumberPhone}
                                        type="text"
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div>
                                    <label>Email</label>
                                    <input
                                        name="userEmail"
                                        value={userEmail}
                                        type="email"
                                        pattern="[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div>
                                    <label>Confirmez votre Email</label>
                                    <input
                                        name="confirmEmail"
                                        type="email"
                                        value={confirmEmail}
                                        pattern="[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                {
                                    newSubscription.confirmEmail && newSubscription.userEmail !== newSubscription.confirmEmail &&
                                    (
                                        <h6 id='confirm-email'>Vous avez renseigné des emails différents</h6>
                                    )
                                }
                            </div>

                            <div id='cgu'>
                                <div className='checkbox'>
                                    <input
                                        id='checkbox'
                                        name="CGU"
                                        type="checkbox"
                                        required
                                    />
                                    <label>J’ai lu et j’accepte les <Link to="/help-center/CGU">CGU</Link> de Senpremium. </label>
                                </div>
                            </div>
                            <button
                                onClick={(e) => {
                                    handleSubscriptionRequest(e);
                                }}
                                className='validate-subscription-btn'
                                id='btn-payer'
                                disabled={paymentInProgress}
                            >
                                {paymentInProgress ? 'Traitement en cours...' : 'Payer'}
                            </button>

                        </form>
                    }
                </div>
                <div className='subscription-content-layout content-right-unknownSub'>

                    <div className='promo-code' onClick={() => ActiveDiscount("promo")}>
                        <div className='details-promo-code'>
                            <FontAwesomeIcon icon={faTicket} className='coupon-icon' />
                            <h4 > Appliquer un code promo</h4>
                        </div>
                        <h3>{`>`}</h3>
                    </div>
                    {
                        applyCodePromo &&
                        (<div className='add-promo-code'>
                            <input type="text" placeholder='Code' onChange={(e) => handleChangeValue(e)} />
                            <button onClick={handleApplyPromo}>Appliquer</button>

                        </div>)
                    }
                    <div className='promo-code' onClick={() => ActiveDiscount("points")}>
                        <div className='details-promo-code'>
                            <FontAwesomeIcon icon={faGift} className='coupon-icon' />
                            <h4 > Appliquer Mes points</h4>
                        </div>
                        <h3  >{`>`}</h3>
                    </div>

                    <h4>Détails de la commande</h4>
                    <div className='img-product'>
                        <img src={`${config.img_url}/${product.productImage}`} alt='img-product' />
                    </div>
                    <div className='points-win'>
                        <span>Points gagné :</span>
                        <div className='points-win-details'>
                            <FontAwesomeIcon icon={faGift} className='iocn-win' />
                            <h4> {winPoints()} </h4>
                        </div>
                    </div>
                    <div className='subscription-options'>
                        <div className='subscription-options-details'>
                            <div>
                                <h4>{product.productName} {optionSub}</h4>

                                {(product && product.promo?.InPromo && (product.promo?.codePromo === codePromo)) || (pointsApplied > 0) ?
                                    (
                                        <div>
                                            {!smarTvPrice && <h4 className='style-price' style={{ textDecoration: 'line-through red', color: "gray" }}> FCFA {product.productPrice} </h4>}
                                            {!smarTvPrice && <h4 className='style-price'> FCFA {product.promo?.productPricePromo} </h4>}
                                            {smarTvPrice && <h4 className='style-price' style={{ textDecoration: 'line-through red', color: "gray" }}> FCFA {smarTvPrice} </h4>}
                                            {smarTvPrice && <h4 className='style-price'> FCFA {smarTvPrice - discountedPrice} </h4>}
                                        </div>
                                    ) :
                                    (
                                        <div>
                                            {!smarTvPrice && <h4 className='style-price'> FCFA {product.productPrice} </h4>}
                                            {smarTvPrice && <h4 className='style-price'> FCFA {smarTvPrice} </h4>}
                                        </div>
                                    )

                                }

                            </div>
                            <div className='counter'>
                                <button type="button" onClick={decrement} className='counter-params'>-</button>
                                <button type="button" className='counter-params' id='quantity'>{quantity}</button>
                                <button type="button" onClick={increment} className='counter-params'>+</button>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className='subscription-options-details sub-total'>
                        <p>Sub Total </p>
                        {(product && product.promo?.InPromo && (product.promo?.codePromo === codePromo)) || (pointsApplied > 0) ?
                            (
                                <div>
                                    {!smarTvPrice && <h3 > FCFA {(product.promo?.productPricePromo) * quantity} </h3>}
                                    {smarTvPrice && <h3 > FCFA {(smarTvPrice - discountedPrice) * quantity} </h3>}
                                </div>
                            ) :
                            (
                                <div>
                                    {!smarTvPrice && <h3> {product.productPrice * quantity} FCFA </h3>}
                                    {smarTvPrice && <h3> {smarTvPrice * quantity} FCFA </h3>}
                                </div>
                            )

                        }

                    </div>
                    <div className='subscription-options-details frais-payment'>
                        <p>Frais Paiement </p>
                        <h3> 2%</h3>
                    </div>
                    <div className='subscription-options-details sub-total'>
                        <p>Total </p>
                        {(product && product.promo?.InPromo && (product.promo?.codePromo === codePromo)) || (pointsApplied > 0) ?
                            (
                                <div>
                                    {!smarTvPrice && <h3 className='total-price' > FCFA {((product.promo?.productPricePromo) * quantity) + (product.productPrice * 0.02)} </h3>}
                                    {smarTvPrice && <h3 className='total-price' > FCFA {((smarTvPrice - discountedPrice) * quantity) + (product.productPrice * 0.02)} </h3>}
                                </div>
                            ) :
                            (
                                <div>
                                    {!smarTvPrice && <h3 className='total-price'> {(product.productPrice * quantity) + (product.productPrice * 0.02)} FCFA </h3>}
                                    {smarTvPrice && <h3 className='total-price'> {(smarTvPrice * quantity) + (product.productPrice * 0.02)} FCFA </h3>}
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
            <Footer />

            <Modal
                isOpen={reNewal}
                onRequestClose={handleReNewal}
                contentLabel="Réabonnement Modal"
                className="modal"
                overlayClassName="overlay"
            >
                <div className='wish-renewal'>
                    <div className='wish-renewal-title'>
                        <h2>Faire un réabonnement avec Senpremium.</h2>

                    </div>
                    <div className='wish-renewal-details'>
                        <div>
                            <h3> 1. Connexion/Inscription</h3>
                            <p>Cliquez sur "Connexion" pour vous connecter ou créer un compte.</p>
                        </div>

                        <div>
                            <h3> 2. Mes Abonnements</h3>
                            <p>Allez dans "Mes Abonnements" via "Compte" dans la barre de navigation.                            </p>
                        </div>
                        <div>
                            <h3> 3. Retrouver vos abonnements</h3>
                            <p>Consultez tous vos abonnements antérieurs, même sans compte</p>
                        </div>
                        <div>
                            <h3> 4. Effectuer votre réabonnement</h3>
                            <p>Sélectionnez et réabonnez-vous.</p>
                        </div>
                    </div>
                    <div className='wish-renewal-old'>
                        <div className='line'></div>
                        <div>
                            <h3>Pour les anciens utilisateurs de Senpremium :</h3>
                            <p>Pour conserver vos anciens accès, connectez-vous et recherchez l'abonnement correspondant. Fournissez les informations de votre commande et les anciens accès sur la page de paiement.</p>

                        </div>
                    </div>
                    <div className='wish-renewal-btn'>
                        <button onClick={handleReNewal} className='link-btn-wish link-btn-close'> <img src={iconClose} /> <span>Fermer</span> </button>
                        <Link to="/signin" className='link-btn-wish'><img src={iconSignin} /> <span>Se Connecter</span> </Link>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default UnknownNewSubscription;
