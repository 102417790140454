import React, { useContext, useEffect, useState } from 'react';
import NavBar from '../Layouts/NavBar';
import config from "../Services/config.js";
import Instance from '../Services/Instance';
import { useParams } from "react-router-dom";
import { Link } from 'react-router-dom';
import Footer from "../Layouts/Footer.js";
import "../Assets/Styles/Subscriptions.css";
import freeMoney from "../Assets/Imgs/icons/freemoney.PNG";
import wave from "../Assets/Imgs/icons/wave.PNG";
import masterCard from "../Assets/Imgs/icons/mastercard.PNG";
import visa from "../Assets/Imgs/icons/visa.PNG";
import om from "../Assets/Imgs/icons/orangemoney.PNG";
import mtn from "../Assets/Imgs/icons/mtn.PNG";
import moov from "../Assets/Imgs/icons/moovmoney.PNG";
import { decryptData } from '../Services/EncryptData.js';
import attentionIcon from "../Assets/Imgs/icons/infos avertissement.svg"
import iconPhonePcActive from "../Assets/Imgs/icons/btn_phonePc_active.png"
import iconPhonePcEnable from "../Assets/Imgs/icons/btn_phonePc_enable.png"
import iconSmartTVEnable from "../Assets/Imgs/icons/btn_smarttv_enable.png"
import iconSmartTVActive from "../Assets/Imgs/icons/btn_smarttv_active.png"
import { DataContext } from '../Services/DataContext.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart} from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import CustomListLayout from '../Layouts/CustomListLayout.js';
import moreFrequently from "../Assets/Imgs/icons/mostFrequently.svg"
import ShowCart from '../Components/Cart/ShowCart.js';
import flash from "../Assets/Imgs/icons/flash.svg"
import DescriptionSubs from '../Components/Subscriptions/DescriptionSubs.js';
import DropDownProduct from '../Components/Subscriptions/DropDownProduct.js';
import loading from "../Assets/Imgs/icons/loading2.gif"
import categoryIcon from "../Assets/Imgs/category.svg"
import productType from "../Assets/Imgs/product_type.svg"

function Subscription() {
    const [error, setError] = useState("");
    const [product, setProduct] = useState(null); // Initialisez avec `null` pour éviter des erreurs
    const [faqs, setFaqs] = useState([]);
    const [isInWishlist, setIsInWishlist] = useState(false);
    const [wishlist, setWishlist] = useState([]);
    const [dataUser__, setDataUser__] = useState(null);
    const [optionNetflix, setOptionNetflix] = useState(false);

    const { id } = useParams();
    const { video, music, services, securite, design, carteCadeauEtJeux, gaming, education, logiciel, currentProduct, setCurrentProduct, addToCart, showCart, setShowCart } = useContext(DataContext);


    library.add(faHeart);

    // Synchroniser `currentProduct` avec `id` des paramètres
    useEffect(() => {
        // Synchroniser currentProduct avec id des paramètres
        if (id && currentProduct !== id) {
            setCurrentProduct(id); // Met à jour currentProduct dans le contexte
        }

        // Cleanup pour réinitialiser currentProduct à null quand on quitte la page
        return () => {
            setCurrentProduct(null);
        };
    }, [id]);


    // Récupérer l'utilisateur depuis le localStorage au chargement
    useEffect(() => {
        const user__ = localStorage.getItem("user");
        if (user__) {
            const decryptedUser = decryptData(user__);
            setDataUser__(decryptedUser);
        }
    }, []);

    // Récupérer les données du produit basé sur `currentProduct`
    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const result = await Instance.get(`${config.api_url}/show-product/${currentProduct}`);
                setProduct(result.data.product);
                window.scrollTo(0, 0); // Remonter en haut de la page
            } catch (err) {
                setError(err.message || "Erreur lors de la récupération du produit.");
            }
        };

        if (currentProduct) {
            fetchProduct();
            optionPhonePc(); // Réinitialiser les options pour Netflix
        }
    }, [currentProduct]);

    // Mettre à jour les FAQs en fonction des données du produit
    useEffect(() => {
        const defaultFaqs = product?.faq || [];
        if (defaultFaqs.length > 0) {
            defaultFaqs[0].statusFaq = true; // Activer le premier FAQ par défaut
        }
        setFaqs(defaultFaqs.map((faq) => ({ ...faq, statusFaq: faq.statusFaq || false })));
    }, [product]);

    // Récupérer la liste de souhaits lorsque `dataUser__` est défini
    useEffect(() => {
        const fetchWishlist = async () => {
            try {
                if (dataUser__) {
                    const result = await Instance.get(`${config.api_url}/wish-list/${dataUser__._id}`);
                    setWishlist(result.data.wishlist);
                }
            } catch (err) {
                setError("Erreur lors de la récupération de la liste de souhaits.");
            }
        };

        fetchWishlist();
    }, [dataUser__]);

    // Vérifier si le produit est dans la liste de souhaits
    useEffect(() => {
        if (wishlist.length > 0 && product) {
            const inWishlist = wishlist.some((item) => item.product._id === product._id);
            setIsInWishlist(inWishlist);
        }
    }, [wishlist, product]);

    const optionSmartTV = () => {
        setOptionNetflix(true);
        localStorage.setItem("smartTvPrice", product.productPrice2);
        localStorage.setItem("netflixType", "Smart TV");
    };

    const optionPhonePc = () => {
        setOptionNetflix(false);
        localStorage.removeItem("smartTvPrice");
        localStorage.removeItem("netflixType");
    };

    const toggleFaq = (index) => {
        setFaqs((prevFaqs) =>
            prevFaqs.map((faq, i) => ({
                ...faq,
                statusFaq: i === index ? !faq.statusFaq : faq.statusFaq,
            }))
        );
    };

    const toggleWishlist = async () => {
        if (!dataUser__) {
            return alert("Vous devez vous connectez pour aimer ce produit");
        }
        if (isInWishlist) {
            return alert("Ce produit a été déjà ajouté à votre liste de Souhaits");
        }
        try {
            const result = await Instance.post(`${config.api_url}/new-wish-product`, {
                userId: dataUser__._id,
                productId: product._id,
            });

            if (result.status === 201) {
                setIsInWishlist(true);
            }
        } catch (err) {
            setError("Erreur lors de l'ajout à la liste de souhaits.");
        }
    };

    // Fonction pour mélanger les éléments
    const shuffleArray = (array) => {
        return array.sort(() => Math.random() - 0.5);
    };

    // Récupérer les produits similaires
    const shuffledListElements = () => {
        if (!product || !product.productCategory) return [];
        let listElementsScrolling = [];

        switch (product.productCategory) {
            case "Streaming Video":
                listElementsScrolling = video;
                break;
            case "Music":
                listElementsScrolling = music;
                break;
            case "Services":
                listElementsScrolling = services;
                break;
            case "Securite":
                listElementsScrolling = securite;
                break;
            case "Design":
                listElementsScrolling = design;
                break;
            case "Cartes Cadeaux et Jeux Video":
                listElementsScrolling = carteCadeauEtJeux;
                break;
            case "Cartes Cadeaux":
                listElementsScrolling = carteCadeauEtJeux;
                break;
            case "Education":
                listElementsScrolling = education;
                break;
            case "Logiciel":
                listElementsScrolling = logiciel;
                break;
            case "Gaming":
                listElementsScrolling = gaming;
                break;
            default:
                return [];
        }

        return shuffleArray(listElementsScrolling);
    };

    const similarProducts = shuffledListElements();
    //Show cart
    const handleShowCart = () => {
        setShowCart(true)
    }
    // Affichage d'un état de chargement
    if (!product) {
        return (
            <div className="subscription">
                <div className="loading">
                    <img className='.loading-gif ' src={loading} alt="" />
                    <p>
                        Chargement du produit...
                    </p>

                </div>
            </div>
        );
    }

    return (
        <div className='subscription '>
            <NavBar />

            {showCart &&
                <div >
                    <div className='cart-show-popup' >
                        <ShowCart />
                    </div>
                </div>
            }
            {showCart &&
                <div className='popup-cart'>
                </div>
            }


            <div className='header-subscription'>
                <div className='sous-navbar' id={`${showCart ? "bg-subs-show-cart sous-navbar-sub " : "sous-navbar-sub "}`}>
                    <Link to="/" className='link-sous-navbar'> Home </Link>
                    <span>/</span>
                    <Link to="/products" className='link-sous-navbar'> Abonnements </Link>
                    <span>/</span>
                    <Link to={``} className="link-sous-navbar" id='activeLink'> Détails</Link>
                </div>
                <div className='subscription-delivery' id={`${showCart ? "bg-subs-show-cart" : ""}`}>
                    <DescriptionSubs />
                </div>

            </div>
            <div className='card-subs subscription-content-layout subscription-container subscription-container-product' id={` ${showCart ? "bg-subs-show-cart " : ""}`} >

                <div className='subscription-content-details'>
                    <div className='content-layout content-left'>
                        <div className='content-left-container-top'>
                            <div className={`details-promo-wishlist ${product.promo?.InPromo ? 'promo-wishlist' : 'no-promo-wishlist'}`}>
                                {product.promo?.InPromo &&
                                    <div className='in-promo-subscription'>
                                        <img className='flash' src={flash} alt="flash" />
                                        <p className='text-in-promo'>En Promo</p>
                                    </div>}
                                <div className='content-wish-icon'>
                                    <FontAwesomeIcon icon={faHeart}
                                        className={`wish-icon ${isInWishlist ? 'wish-icon-active' : ''}`}
                                        onClick={toggleWishlist}
                                    />
                                </div>
                            </div>
                            <div className='img-product'>
                                <img src={`${config.img_url}/${product.productImage}`} id="img-product-subscription" alt="img-product" />
                            </div>
                        </div>
                        <div className='content-left-container-bottom'>
                            <h2 className='product-name'>{product.productName}</h2>
                            <div className='content-right-details content-right-details-web'>

                                <div className='content-right-details-child'>
                                    <img src={categoryIcon} alt='icon-category' className='content-right-details-child-icon' />
                                    <div>
                                        <span>Catégorie</span>
                                        <span className='text-content-right-details' >{product.productCategory}</span>

                                    </div>
                                </div>
                                {product.productType &&
                                    <div className='content-right-details-child'>
                                        <img src={productType} alt='icon-type' className='content-right-details-child-icon' />
                                        <div>
                                            <span>Type</span>
                                            <span className='text-content-right-details'>{product.productType}</span>

                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='content-layout content-right'>
                        <div className='content-description-others'>
                            <div >
                                {
                                    !product.promo?.InPromo === true ?
                                        <div>
                                            {!optionNetflix && <h2 className='style-price'>{product.productPrice} FCFA</h2>}
                                            {optionNetflix && <h2 className='style-price'>{product.productPrice2} FCFA</h2>}
                                        </div>
                                        :

                                        <div className='product-price-inSubscription' >
                                            {!optionNetflix && <h2
                                                style={{
                                                    textDecoration: "line-through",
                                                    textDecorationColor: "red",
                                                    color: 'gray',
                                                    fontStyle: "italic",
                                                }}
                                                className='style-price'>{product.productPrice} FCFA</h2>}
                                            {!optionNetflix && <h2 className='style-price'>{product.promo?.productPricePromo} FCFA</h2>}

                                        </div>

                                }

                            </div>
                            <div className='similar-products'>
                                <div className='drop-down-products'>
                                    {
                                        (product.productCategory.includes("Cartes Cadeaux")) || (product.productCategory.includes("Gaming")) ?
                                            <p id='content-product-description' >Sélectionnez le Montant</p>
                                            :
                                            <p id='content-product-description' >Choisissez la Durée</p>

                                    }
                                    <DropDownProduct productName={product.productName} productId={product._id} />
                                </div>
                            </div>
                            <div className='content-right-details content-right-details-mobile'>

                                <div className='content-right-details-child'>
                                    <img src={categoryIcon} alt='icon-category' className='content-right-details-child-icon' />
                                    <div>
                                        <span>Catégorie</span>
                                        <span className='text-content-right-details' >{product.productCategory}</span>

                                    </div>
                                </div>
                                {product.productType &&
                                    <div className='content-right-details-child'>
                                        <img src={productType} alt='icon-type' className='content-right-details-child-icon' />
                                        <div>
                                            <span>Type</span>
                                            <span className='text-content-right-details'>{product.productType}</span>

                                        </div>
                                    </div>
                                }
                            </div>
                            <div>
                                <p id='content-product-description'>Description</p>
                                <p className='content-description'>{product.productDescription}</p>
                            </div>
                        </div>

                        <div className='content-option-phone-pc'>
                            <div className={product.productName
                                && product.productName.toLocaleLowerCase().includes("netflix")
                                && !product.productName.includes("NETFLIX Pack Family")
                                && !product.productName.includes("Netflix Duo")
                                ? 'type-abonnement' : "type-abonnemen-enable"}>
                                {product.productName && product.productName.toLocaleLowerCase().includes("netflix")
                                    && !product.productName.includes("NETFLIX Pack Family")
                                    && !product.productName.includes("Netflix Duo")
                                    && (
                                        <div className='details-option-phone-pc'>
                                            <h3>Type d'abonnement Netflix</h3>
                                            <div className='option-type-abonnement'>
                                                <img src={optionNetflix ? iconPhonePcEnable : iconPhonePcActive} alt="phone-pc" onClick={optionPhonePc} />
                                                <img src={optionNetflix ? iconSmartTVActive : iconSmartTVEnable} alt="smartTV" onClick={optionSmartTV} />
                                            </div>
                                        </div>
                                    )}


                            </div>

                        </div>

                        {product?.productInStock === "Available" ?
                            <div className='validate-subscription-infos'>
                                <Link to={""} onClick={() => { addToCart(product._id); handleShowCart() }} className='validate-subscription-btn' id='btn-buy-now'>Ajouter au panier</Link>
                                <Link to={dataUser__ ? `/new-subscription/${product._id}` : `/unknown-new-subscription/${product._id}`} className='validate-subscription-btn'>Valider</Link>
                            </div>
                            :
                            (
                                <div className='validate-subscription-infos'>
                                    <Link to={""} className='validate-subscription-btn' id='btn-in-rupture'>En Rupture</Link>
                                </div>)
                        }

                    </div>

                </div>


                {product.productName
                    && product.productName.toLocaleLowerCase().includes("netflix")
                    && !product.productName.includes("NETFLIX Pack Family")
                    && !product.productName.includes("Netflix Duo")
                    && <div className='attention'>
                        <img src={attentionIcon} alt="attention" />
                        {!optionNetflix && <p>  Cette offre est valable pour les <span className='option-netflix-active'>téléphones ou PC</span>. Si vous avez une Smart TV, veuillez choisir l'offre NETFLIX Smart TV </p>}
                        {optionNetflix && <p> Cette offre est valable pour les <span className='option-netflix-active'>Smart TV</span>. Si vous avez un téléphones ou PC, veuillez choisir l'offre NETFLIX Phone/Pc </p>}
                    </div>}

                {product.productAttention && (product.productAttention !== "" && product.productAttention !== "undefined") && <div className='attention'>
                    <img src={attentionIcon} alt="attention" />
                    <p className='avertissement'> {product.productAttention} </p>
                </div>}
            </div>

            <div className=' subscription-container subscription-container-faqs' id={`${showCart ? "bg-subs-show-cart" : ""}`}>
                <h1 >FAQ's</h1>
                <div >
                    {faqs.map((faq, index) => (
                        <div className='faq-content' key={index}>
                            <div className='faq-item'>
                                <p>{faq.question}</p>
                                {faq.statusFaq ? (
                                    <h1 onClick={() => toggleFaq(index)} className='chevron arrow-faq'>-</h1>
                                ) : (
                                    <h1 onClick={() => toggleFaq(index)} className='arrow-faq'>+</h1>
                                )}
                            </div>
                            {faq.statusFaq && (
                                <p className='faq-description'>{faq.answer}</p>
                            )}
                        </div>
                    ))}
                </div>
            </div>
            <div className=' content-payment' id={`${showCart ? "bg-subs-show-cart" : ""}`}>
                <div className='description-payment'>
                    <h2 className='title-payment'> <span id='payment'>Paiement </span> <span id='secure'>Sécurisé </span> </h2>
                </div>
                <div className='payment-secure-img'>
                    <img src={wave} alt="img-payment" />
                    <img src={freeMoney} alt="img-payment" />
                    <img src={om} alt="img-payment" />
                    <img src={masterCard} alt="img-payment" />
                    <img src={visa} alt="img-payment" />
                    <img src={mtn} alt="img-payment" />
                    <img src={moov} alt="img-payment" />
                </div>
            </div>
            <div className=' subscription-container' id={`${showCart ? "bg-subs-show-cart" : ""}`} >
                <CustomListLayout
                    listItems={similarProducts}
                    typeName="Produit Similaires"
                    iconType={moreFrequently}
                />
            </div>
            <Footer />

        </div >
    )
}

export default Subscription;
