import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../../Layouts/NavBar';
import "../../Assets/Styles/Referral.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpFromBracket, faBullhorn, faCopy, faShare, faShareAlt, faShareNodes, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import PopupReferral from './PopupReferral';
import Instance from '../../Services/Instance';
import config from '../../Services/config';
import { decryptData } from '../../Services/EncryptData';
import news from "../../Assets/Imgs/news.png"
import referralBanner from "../../Assets/Imgs/5_Reduction2.png"
import { ChevronRight, Gift, Minus, Plus, Share2, UsersRound } from 'lucide-react'
import { faqsReferrals } from "./FaqsReferrals"
import Footer from '../../Layouts/Footer';
import iconOffre from "../../Assets/Imgs/Icon Offre.png"


function InviteFriend() {
  const [linkReferral, setLikReferral] = useState("")
  const [referral, setReferral] = useState(null)
  const [faqs, setFaqs] = useState(faqsReferrals);

  const user__ = localStorage.getItem("user")
  const dataUser__ = user__ === null ? null : decryptData(user__)

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const value = params.get("referral");
    setReferral(value || null);
  }, []);

  const generateLinkReferral = async () => {
    try {
      const res = await Instance.get(`${config.api_url}/refer-friend/${dataUser__._id}`)
      // console.log(res.data.referralLink);
      setLikReferral(res.data.referralLink)

    } catch (error) {
      console.error("Error:", error)
    }
  }
  useEffect(() => {
    generateLinkReferral()
  }, []);

  const [showPopups, setShowPopups] = useState(false); // Utilisez un tableau pour les popups

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        // Met à jour le tableau showPopups pour montrer le popup pour cet index
        setShowPopups(true);

        // Cache le popup après 3 secondes
        setTimeout(() => {
          setShowPopups(false);
        }, 3000);
      })
      .catch((err) => {
        console.error("Erreur lors de la copie :", err);
      });
  };

  const toggleFaq = (index) => {
    setFaqs((prevFaqs) =>
      prevFaqs.map((faq, i) => ({
        ...faq,
        statusFaq: i === index ? !faq.statusFaq : faq.statusFaq,
      }))
    );
  };

  return (
    <div>
      <NavBar />
      <div className='global-container-referral'>
        <div id='referral-container' className=''>
          <div className='container-referral-left'>
            <img className='news-in-referral' src={news} alt="" />
            <div className='banner-referral' >
              {
                !referral && dataUser__ ?
                  (<>
                    <div className='title-referral'>
                      <span className='invite-friend'>Invitez</span> Un Ami
                      <p className='win-reduction'>Et gagnez <span className='off5'>5%</span> de réduction ! </p>
                    </div>
                    <div className='details-referral'>
                      <div className='item-details-referral'>
                        <div className='icon-details-referral'>
                          <Share2 />
                        </div>
                        <div>
                          <h2>Partagez votre lien</h2>
                          <p>Envoyez votre Lien unique à vos amis</p>
                        </div>
                      </div>

                      <div className='item-details-referral'>
                        <div className='icon-details-referral'>
                          <UsersRound />                        </div>
                        <div>
                          <h2>Ils s'inscrivent</h2>
                          <p>Vos amis créent leur compte</p>
                        </div>
                      </div>

                      <div className='item-details-referral'>
                        <div className='icon-details-referral'>
                          <Gift />
                        </div>
                        <div>
                          <h2>Profitez ensemble</h2>
                          <p>Vous gagnez tous les deux 5% de réduction</p>
                        </div>
                      </div>

                    </div>
                    <div className='copy-link-referral' >
                      <div className='send-link-referral'>
                        <p> {`https://senpremium.com${linkReferral}`}</p>
                        <FontAwesomeIcon
                          icon={faCopy}
                          className="copy-icon"
                          onClick={() => copyToClipboard(`https://senpremium.com${linkReferral}`)}

                        />
                      </div>
                      <PopupReferral linkReferral={linkReferral} />
                    </div>
                    {showPopups && (
                      <div className="copy-popup copy-popup-referral">
                        Votre Lien de parainnage a été copié dans le presse-papiers !
                      </div>
                    )}
                  </>

                  )
                  : referral && !dataUser__ ?

                    (
                      <>
                        <div className='title-referral'>
                          <span className='invited-referral'>Un ami </span> vous parainne !
                        </div>
                        <p className='new-user-by-referral'>
                          Profitez dès maintenant d’une réduction exclusive de <span className='off5'>5% sur votre premier achat !</span> <br />
                          Ne laissez pas passer cette opportunité! <br />
                          Rejoignez-nous dès aujourd’hui et commencez à économiser.

                        </p>

                        <div className='item-details-referral'>
                          <img className='icon-offre' src={iconOffre} alt="" />
                          <p> Offre réservée aux nouveaux utilisateurs. </p>
                        </div>
                        <div id='new-user-signup' className='signup-referral'>
                          {/* <Link to={`https://senpremium.com/signup?referral=${dataUser__._id}`} className='link-signup-referral' > */}
                          <Link to={`http://localhost:3000/signup?referral=${referral}`} className='link-signup-referral' >
                            {/* <FontAwesomeIcon icon={faUserPlus} className='icon-referral' /> */}
                            <span> Profitez !</span>
                          </Link>
                        </div>
                      </>
                    ) :
                    (
                      <>
                        <div className='title-referral'>
                          <span className='invite-friend'>Invitez un Ami <br />  </span> et économisez ensemble !
                          <p className='infos-referrals'>
                            Partagez Senpremium avec vos amis et profitez de 5% de réduction chacun ! <br />
                            C’est simple : invitez, partagez et économisez !
                          </p>
                        </div>

                        <div className='details-referral'>
                          <div className='item-details-referral'>
                            <div className='icon-details-referral'>
                              <Share2 />
                            </div>
                            <div>
                              <h2>Partagez votre lien</h2>
                              <p>Envoyez votre Lien unique à vos amis</p>
                            </div>
                          </div>

                          <div className='item-details-referral'>
                            <div className='icon-details-referral'>
                              <UsersRound />                        </div>
                            <div>
                              <h2>Ils s'inscrivent</h2>
                              <p>Vos amis créent leur compte</p>
                            </div>
                          </div>

                          <div className='item-details-referral'>
                            <div className='icon-details-referral'>
                              <Gift />
                            </div>
                            <div>
                              <h2>Profitez ensemble</h2>
                              <p>Vous gagnez tous les deux 5% de réduction</p>
                            </div>
                          </div>

                        </div>

                        <div className='signup-referral'>
                          {/* <Link to={`https://senpremium.com/signup?referral=${dataUser__._id}`} className='link-signup-referral' > */}
                          <Link to={`https://senpremium.com/signin`} className='link-signup-referral' >
                            {/* <FontAwesomeIcon icon={faUserPlus} className='icon-referral' /> */}
                            <p> Commencez  </p>
                            <ChevronRight className='chevron-right' />
                          </Link>
                        </div>
                      </>
                    )
              }
            </div>
          </div>

          <div className=' container-referral-right layout-banner-referral'>
            <img id='img-referral-banner' src={referralBanner} alt="banner" />
          </div>

        </div>

      </div>
      <div className='faqs-referrals'>
          <h1 className='faq-referrals-title'>FAQ - Programme de Parrainage Senpremium </h1>
          <div className='faq-referrals-content'>
            {faqs.map((faq, index) => (
              <div className='faq-referrals-card' key={index}>
                <div className='faq-item'>
                  <p>{faq.question}</p>
                  {faq.statusFaq ? (
                    <h1 onClick={() => toggleFaq(index)} className='chevron arrow-faq'> <Minus />  </h1>
                  ) : (
                    <h1 onClick={() => toggleFaq(index)} className='chevron arrow-faq'><Plus />  </h1>
                  )}
                </div>
                {faq.statusFaq && (
                  <p className='faq-description'>{faq.answer}</p>
                )}
              </div>
            ))}
          </div>
          <h5 className='faq-referrals-title'>Besoin d’aide ? Contactez notre support à support@senpremium.com. 🚀 </h5>
        </div>
    </div>
  )
}

export default InviteFriend