import { RWebShare } from "react-web-share";
import "../../Assets/Styles/Referral.css"
import { Share2 } from "lucide-react";


const PopupReferral = ({ linkReferral }) => {

    return (
        <div className="content-popup-copy">
            <RWebShare
                data={{
                    title: "Senpremium",
                    text: "Inviter Un Ami, et Obtenez 5% de réduction tous les deux à vos prochains Achats",
                    url: linkReferral,
                }}
            >
                <div className='share-referral-link'>
                    <div className='icon-details-referral'>
                        <Share2 />
                    </div>                    
                    <span>Partager  </span>
                </div>
            </RWebShare>
        </div>
    );
};

export default PopupReferral;