/* eslint-disable no-undef */
import React, { useEffect, useRef, useState } from 'react';
import NavBar from '../../Layouts/NavBar';
import config from '../../Services/config.js';
import Instance from '../../Services/Instance';
import { useParams } from 'react-router-dom';
import '../../Assets/Styles/Subscriptions.css';
import levels from "../../Assets/Imgs/icons/icon-levels-subscriptions.svg"
import Footer from '../../Layouts/Footer';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { decryptData } from '../../Services/EncryptData.js';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGift, faTicket } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import loadingImg from "../../Assets/Imgs/icons/loading2.gif"

function NewSubscription() {
    // Déclaration des états
    const [error, setError] = useState('');
    const [product, setProduct] = useState([]);
    const [subscription, setSubscription] = useState([]);
    const [quantity, setQuantity] = useState(1);
    const [loading, setLoading] = useState(true);
    const [paymentInProgress, setPaymentInProgress] = useState(false); // État pour suivre l'état de la demande de paiement
    const [userReferrals, setUserReferrals] = useState(0)
    const [newUserReferrals, setNewUserReferrals] = useState(0)
    const [licenseToReserve, setLicenseToReserve] = useState({
        statusLicenseToReserve: false,
        subscriptionEmailKey: "",
        subscriptionPWKey: "",
    })
    const [priceSub, setPriceSub] = useState(0)

    // Récupération de l'identifiant du produit depuis les paramètres de l'URL
    const params = useParams();
    let id, ref;
    if (Object.keys(params)[0] === 'ref') {
        ref = params.ref
    } else if (Object.keys(params)[0] === 'id') {
        id = params.id
    }

    //Recuperation de l'id de la commande 

    // Récupération de l'utilisateur depuis le stockage local
    const user__ = localStorage.getItem("user")
    const user = user__ === null ? null : decryptData(user__)

    const { subscriptionEmailKey, subscriptionPWKey, statusLicenseToReserve } = licenseToReserve;

    //Add a Promo
    // toast.configure();

    const [codePromo, setCodePromo] = useState("")
    const [applyCodePromo, setApplyCodePromo] = useState(false);

    const [pointsApplied, setPointsApplied] = useState(0);
    const [validatePoint, setValidatePoint] = useState(false);
    const [applyDiscountedPricePoints, setApplyDiscountedPricePoints] = useState(false);
    const [ipAddress, setIpAddress] = useState(null);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setLicenseToReserve({ ...licenseToReserve, [name]: value });
    };
    const handleChangeStatus = () => { setLicenseToReserve({ ...licenseToReserve, statusLicenseToReserve: !statusLicenseToReserve }) }

    // Effet de chargement initial pour récupérer les détails du produit
    useEffect(() => {
        const fetchData = async () => {
            try {
                if (id) {
                    const result = await Instance.get(`${config.api_url}/show-product/${id}`);
                    setProduct(result.data.product);

                } else if (ref) {
                    const result = await Instance.get(`${config.api_url}/show-subscription/${ref}`);

                    if (result.data.subscription) {
                        setSubscription(result.data.subscription)
                        id = result.data.subscription.productId

                        const res = await Instance.get(`${config.api_url}/show-product/${id}`);
                        setProduct(res.data.product);
                    }

                }

                const res = await Instance.get(`${config.api_url}/referrals-pending/${user._id}`);
                const response = await Instance.get(`${config.api_url}/referrals-completed/${user._id}`);


                if (res.data?.referrals?.length !== 0) {
                    setNewUserReferrals(0.05); //S'il y a des referrals on recupére directement la reduction (ici 5% on s'attend à manipuler directement cette valeur avec celle de la totale
                }
                if (response.data?.referrals?.length !== 0) {
                    setUserReferrals(0.05 * response.data?.referrals?.length);
                }
                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };

        window.scrollTo(0, 0);
        fetchData();
    }, [id, user._id]);

    // Incrémentation de la quantité
    const increment = () => {
        setQuantity(quantity + 1);
    };

    // Décrémentation de la quantité
    const decrement = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    };

    //Recupérer le prix du smart tv 
    const smarTvPrice = localStorage.getItem('smartTvPrice');
    let netflixType = localStorage.getItem("netflixType")
    let optionSub = "";

    useEffect(() => {
        let newPriceSub = smarTvPrice ? smarTvPrice - pointsApplied : product.productPrice - pointsApplied;
        setPriceSub(newPriceSub);
    }, [smarTvPrice, pointsApplied, product]); // Déclenche le recalcul uniquement quand ces valeurs changent

    if (netflixType) {
        optionSub = `- ${netflixType}`
    } else if (!netflixType && product.productName && product.productName.toLocaleLowerCase().includes("netflix") && !product.productName.includes("NETFLIX Pack Family") && !product.productName.includes("Netflix Duo")) {
        optionSub = "- Phone/Pc"
    }


    //Add a code promo or discount width points 
    const ActiveDiscount = (type) => {
        if (type === "promo") {
            setApplyCodePromo((prevState) => !prevState);
        } else if (type === "points") {
            setApplyDiscountedPricePoints((prevState) => !prevState);
        }
    };

    let newPromoValue, pointsUsing;
    const handleChangeValue = (e, type) => {
        if (type === "promo") {
            newPromoValue = e.target.value;
        }
        if (type === "points") {
            pointsUsing = (e.target.value)
        }

    }

    const handleApplyPromo = () => {
        if (product && product.promo.InPromo && (product.promo.codePromo === newPromoValue)) {
            setCodePromo(newPromoValue)
            let priceSubNew = product.promo.productPricePromo - pointsApplied;
            setPriceSub(priceSubNew);

            toast.success('😁 Code Promo Appliqué avec succès !!!');
        } else {
            toast.error('🙄 Code Promo Invalide !!!');
        }
    };



    const winPoints = () => {
        let points
        if ((quantity * priceSub) > 25000) {
            points = Math.ceil((quantity * priceSub) / 80)
        } else {
            points = Math.ceil((quantity * priceSub) / 40)
        }
        return points;
    }

    // Appliquer les points
    const handleApplyDiscountPoints = async () => {
        const result = await Instance.get(`${config.api_url}/show-user/${user._id}`);
        const userPoints = result.data.user.point;
        
        if (userPoints >= pointsUsing) {
            console.log(userPoints,pointsApplied);

            const discountPrice = pointsApplied;

            // Vérification pour empêcher d'appliquer Des points SUPP AU PRIX
            if (discountPrice > priceSub - 100) {
                toast.error(`Le maximum de points que vous pouvez appliquer pour cette commande est de ${priceSub - 100}`);
            } else {
                setValidatePoint(true)
                setPointsApplied(pointsUsing)
                toast.success('Vous avez activez vos points pour Bénéficier de réductions')
            }
        } else {
            toast.error('🙄 Vous ne disposez pas de ce nombre de points !!!');
        }
    };
    useEffect(() => {
        const fetchIpAddress = async () => {
            try {
                const response = await axios.get('https://api.ipify.org?format=json');
                setIpAddress(response.data.ip);
            } catch (error) {
                console.error('Error fetching IP address', error);
            }
        };

        fetchIpAddress();
    }, []);

    // console.log("PayTech :", window.PayTech);

    const handleSubscriptionRequest = async () => {
        if (paymentInProgress) return; // Si la demande de paiement est déjà en cours, ne rien faire

        try {
            const orderRequestSubscription = {
                productId: product._id,
                isRenewal: subscription.length !== 0 ? true : false,
                subsId: subscription.length !== 0 ? subscription._id : undefined,
                subscriptionQuantity: quantity,
                productTypeSmart: optionSub,
                subPoints: [winPoints(), pointsApplied],
                statusLicenseToReserve: statusLicenseToReserve,
                userId: user._id,
                subscriptionEmailKey: subscriptionEmailKey || "",
                subscriptionPWKey: subscriptionPWKey || "",
                codePromo: codePromo,
                ipAddress: ipAddress
            }
            setPaymentInProgress(true); 
            const formattedData = { subscriptions: JSON.stringify([orderRequestSubscription]) };
            // console.log("+++++++++", formattedData);
            
            (new PayTech(formattedData)).withOption({
                // requestTokenUrl: Instance.post(`${config.api_url}/new-subscription`),
                requestTokenUrl: (`${config.api_url}/new-subscription`),

                method: 'POST',
                headers: {
                    // "Accept": "text/html",
                    Accept: "application/json",

                },
                prensentationMode: PayTech.OPEN_IN_POPUP,
                willGetToken: function () {

                },
                didGetToken: function (token, redirectUrl) {

                },
                didReceiveError: function (error) {

                },
                didReceiveNonSuccessResponse: function (jsonResponse) {

                }
            }).send();

        } catch (error) {
            console.error('Erreur lors de la demande d\'abonnement :', error);
        } finally {
            setPaymentInProgress(false); // Réinitialiser l'état de la demande de paiement
        }
    };

    useEffect(() => {
        // Ensure that PayTech library is loaded before using the `buy` function
        if (typeof PayTech === 'undefined') {
            console.error("+++++++++++++", 'PayTech library is not loaded.');
            return;
        }
    }, []);


    if (loading) {
        return (
            <div style={{ width: "100vw", height: "100vh", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
                <p>Produit en Chargement</p>
                <img src={loadingImg} alt="" />
            </div>
        )
    }
    return (
        <div className='subscription'>
            <NavBar />

            <div className='subscription-content-layout subscription-levels'>
                <img src={levels} alt="delivery-img" />
            </div>
            <div className='sous-navbar ' >
                <Link to="/" className='link-sous-navbar'> Home </Link>
                <span>/</span>
                <Link to="/products" className='link-sous-navbar'> Abonnements </Link>
                <span>/</span>
                <Link to={`/subscription/${product._id}`} className="link-sous-navbar" > Détails</Link>
                <span>/</span>
                <Link to={`/subscription/${product._id}`} className="link-sous-navbar" id='activeLink'> Informations </Link>

            </div>
            <div className='subscription-content-layout interne-subscription'>
                <div className='promo-code' id='apply-promo' onClick={() => ActiveDiscount("points")}>
                    <div className='details-promo-code'>
                        <FontAwesomeIcon icon={faGift} className='coupon-icon' />
                        <h4 > Appliquer Mes points</h4>
                    </div>
                    <h3  >{`>`}</h3>
                </div>
                {
                    applyDiscountedPricePoints &&
                    (<div className='add-promo-code'>
                        <input type="number" placeholder='points' onChange={(e) => handleChangeValue(e, "points")} />
                        <button onClick={() => handleApplyDiscountPoints()}>Appliquer</button>

                    </div>)
                }

                <div className='promo-code' onClick={() => ActiveDiscount("promo")}>
                    <div className='details-promo-code'>
                        <FontAwesomeIcon icon={faTicket} className='coupon-icon' />
                        <h4 > Appliquer un code promo</h4>
                    </div>
                    <h3>{`>`}</h3>
                </div>
                {
                    applyCodePromo &&
                    (<div className='add-promo-code'>
                        <input type="text" placeholder='Code' onChange={(e) => handleChangeValue(e, "promo")} />
                        <button onClick={handleApplyPromo}>Appliquer</button>

                    </div>)
                }
                <h3>Détails de la commande</h3>
                <div className='img-product'>
                    <img src={`${config.img_url}/${product.productImage}`} alt='img-product' />
                </div>
                <div className='points-win'>
                    <span>Points gagné :</span>
                    <div className='points-win-details'>
                        <FontAwesomeIcon icon={faGift} className='iocn-win' />
                        <h4> {winPoints()} </h4>
                    </div>
                </div>
                <div className='subscription-options'>

                    <div className='subscription-options-details'>

                        <div>
                            <h3> {product.productName} {optionSub}</h3>
                            {/* {product.promo?.InPromo ? */}
                            {/* {(product && product.promo?.InPromo && (product.promo?.codePromo === codePromo)) || validatePoint ?
                                (
                                    <div>
                                        {!smarTvPrice && <h3 className='style-price' style={{ textDecoration: 'line-through red', color: "gray" }}> FCFA {product.productPrice} </h3>}
                                        {smarTvPrice && <h3 className='style-price' style={{ textDecoration: 'line-through red', color: "gray" }}> FCFA {smarTvPrice} </h3>}
                                        <h3 className='style-price'> FCFA {priceSub} </h3>
                                        {/* {smarTvPrice && <h3 className='style-price'> FCFA {priceSub} </h3>
                                    </div>
                                ) :
                                (
                                    <div>
                                        {!smarTvPrice && <h3 className='style-price'> FCFA {product.productPrice} </h3>}
                                        {smarTvPrice && <h3 className='style-price'> FCFA {smarTvPrice} </h3>}
                                    </div>
                                )

                            } */}
                            <div>
                                {!smarTvPrice && <h3 className='style-price'> FCFA {product.productPrice} </h3>}
                                {smarTvPrice && <h3 className='style-price'> FCFA {smarTvPrice} </h3>}
                            </div>
                        </div>

                        <div className='counter'>
                            <button onClick={decrement} className='counter-params'>
                                -
                            </button>
                            <button className='counter-params' id='quantity'>
                                {quantity}
                            </button>
                            <button onClick={increment} className='counter-params'>
                                +
                            </button>
                        </div>
                    </div>

                </div>

                <hr />

                <div className='subscription-options-details sub-total'>
                    <p>Sub Total </p>
                    {/* {product.promo?.InPromo ? */}
                    {/* {(product && product.promo?.InPromo && (product.promo?.codePromo === codePromo)) || validatePoint || (newUserReferrals !== 0) || (userReferrals !== 0) ?
                        (
                            <div>

                                {!smarTvPrice && <h3 className='style-price' style={{ textDecoration: 'line-through red', color: "gray" }}> FCFA {product.productPrice * quantity} </h3>}
                                {smarTvPrice && <h3 className='style-price' style={{ textDecoration: 'line-through red', color: "gray" }}> FCFA {smarTvPrice * quantity} </h3>}
                                <h3 className='style-price'> FCFA {(priceSub * quantity) - ((priceSub * quantity) * userReferrals) - (priceSub * quantity * newUserReferrals)} </h3>
                            </div>
                        ) :
                        (
                            <div>
                                {!smarTvPrice && <h3> {product.productPrice * quantity} FCFA </h3>}
                                {smarTvPrice && <h3> {smarTvPrice * quantity} FCFA </h3>}
                            </div>
                        )

                    } */}

                    <div>
                        {!smarTvPrice && <h3> {product.productPrice * quantity} FCFA </h3>}
                        {smarTvPrice && <h3> {smarTvPrice * quantity} FCFA </h3>}
                    </div>
                </div>

                <div className='subscription-options-details frais-payment'>
                    <p>Frais Paiment </p>
                    <h3> +2%</h3>
                </div>

                {((userReferrals !== 0)) &&
                    <div className='subscription-options-details frais-payment' >
                        <p>  Réducton de Parrainage</p>
                        <h3 className='parrainage-reduction'> {100 * userReferrals}% OFF </h3>
                    </div>}

                {((newUserReferrals !== 0)) &&
                    <div className='subscription-options-details frais-payment' >
                        <p>  Réducton de Parrainage</p>
                        <h3 className='parrainage-reduction'> 5% OFF </h3>
                    </div>}

                {
                    validatePoint && (
                        <div className='subscription-options-details frais-payment' >
                            <p>  Points Appliqués</p>
                            <h3 className='parrainage-reduction'> {pointsApplied}cfa OFF </h3>
                        </div>
                    )
                }

                {
                    (product && product.promo?.InPromo && (product.promo?.codePromo === codePromo)) && (
                        <div className='subscription-options-details frais-payment' >
                            <p>  Promo Appliqué</p>
                            <h3 className='parrainage-reduction'>  {product?.productPrice - product?.promo?.productPricePromo}cfa OFF </h3>
                        </div>
                    )
                }

                <div className='subscription-options-details sub-total'>
                    <p> Total </p>
                    {/* {product.promo?.InPromo ? */}
                    {(product && product.promo?.InPromo && (product.promo?.codePromo === codePromo)) || validatePoint || (newUserReferrals !== 0) || (userReferrals !== 0) ?
                        (
                            <div>
                                {/* {!smarTvPrice && <h3 style={{ textDecoration: 'line-through red', color: "gray" }} >  {(product.productPrice * quantity) + (product.productPrice * 0.02)} FCFA </h3>}
                                {!smarTvPrice && <h3 className='total-price' >                                        {(priceSub * quantity) + (priceSub * 0.02)} FCFA </h3>}
                                {smarTvPrice && <h3 style={{ textDecoration: 'line-through red', color: "gray" }} >  {((smarTvPrice) * quantity) + ((smarTvPrice) * quantity * 0.02)} FCFA</h3>}
                                {smarTvPrice && <h3 className='total-price' >                                        {((smarTvPrice - pointsApplied) * quantity) + ((smarTvPrice - pointsApplied) * quantity * newUserReferrals) - ((smarTvPrice - pointsApplied) * quantity * userReferrals)} FCFA</h3>} */}

                                {!smarTvPrice && <h3 className='style-price' style={{ textDecoration: 'line-through red', color: "gray" }}> FCFA {product.productPrice * quantity + (product.productPrice * quantity * 0.02)} </h3>}
                                {smarTvPrice && <h3 className='style-price' style={{ textDecoration: 'line-through red', color: "gray" }}> FCFA {smarTvPrice * quantity + (smarTvPrice * quantity * 0.02)} </h3>}
                                <h3 className='total-price'> FCFA {(priceSub * quantity) - (priceSub * quantity * userReferrals) - (priceSub * quantity * newUserReferrals) + (((priceSub * quantity) - (priceSub * quantity * userReferrals) - (priceSub * quantity * newUserReferrals)) * 0.02)} </h3>

                            </div>
                        ) :
                        (
                            <div>
                                {!smarTvPrice && <h3 className='total-price'> {(product.productPrice * quantity) + (product.productPrice * quantity * 0.02)} FCFA </h3>}
                                {smarTvPrice && <h3 className='total-price'> {(smarTvPrice * quantity) + (smarTvPrice * quantity * 0.02)} FCFA </h3>}
                            </div>
                        )
                    }
                </div>

                <div className=''>
                    {statusLicenseToReserve === false && product.productCategory !== "Cartes Cadeaux et Jeux Video" && <p className='sub-inprogress' onClick={() => handleChangeStatus()} >Avez vous un compte existant que vous souhaitez Conserver ?</p>}
                </div>
                {
                    statusLicenseToReserve === true &&
                    (

                        <div className="form-reserve-license" >
                            <p className='sub-inprogress' onClick={() => handleChangeStatus()}>Je préfére avoir de nouveaux Accès !!!</p>
                            <p> Veuillez indiquer ici les identifiants du compte que vous souhaitez renouveler.</p>
                            <p style={{ "color": "red", "fontSize": "13px" }}> Attention! Ce compte est le compte de votre abonnement que vous souhaitez rénouveler et non  l'email avec lequel vous vous connecter sur l'application Senpremium.</p>
                            <div className='details-form-reserve-license'>
                                <label>Email du compte existant à renouveler</label>
                                <input
                                    name="subscriptionEmailKey"
                                    value={subscriptionEmailKey}
                                    type="email"
                                    onChange={(e) => handleChange(e)}

                                />
                            </div>
                            <div className='details-form-reserve-license'>
                                <label>Mot de passe du compte existant à renouveler</label>
                                <input
                                    name="subscriptionPWKey"
                                    value={subscriptionPWKey}
                                    type="text"
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                        </div>
                    )
                }

                <button onClick={handleSubscriptionRequest} className='validate-subscription-btn' id='btn-payer' disabled={paymentInProgress}>
                    {paymentInProgress ? 'Traitement en cours...' : 'Payer'}
                </button>

            </div>
            <div className='footer'>
                <Footer />
            </div>
        </div>
    );
}

export default NewSubscription;
