import React, { useContext, useState } from 'react';
import { DataContext } from '../../Services/DataContext';
import "../../Assets/Styles/Subscriptions.css";

const DropDownProduct = ({ productName, productId }) => {
    const { products, setCurrentProduct } = useContext(DataContext);
    const [selectedProductId, setSelectedProductId] = useState(null);
    const [selectedProductName, setSelectedProductName] = useState(productName);

    // Vérifie si productName est défini
    if (!productName) {
        console.error("productName est indéfini.");
        return <div></div>;
    }

    const cleanedProductName = productName.trim(); // Supprime les espaces inutiles

    // Fonction pour obtenir le premier mot (Apple, Google, etc.)
    const getFirstWord = (name) => {
        if (typeof name !== 'string') return '';
        return name.trim().toLowerCase().split(' ')[0]; // Récupère le premier mot sans transformation
    };

    // Fonction pour détecter le pays
    const getCountry = (name) => {
        if (name.toLowerCase().includes("$")) return "$";
        if (name.toLowerCase().includes("€")) return "€";
        return null; // Aucun pays détecté
    };


    // Extraction des informations principales
    const baseWord = getFirstWord(productName.toLowerCase());
    const productCountry = getCountry(productName.toLowerCase());

    const filteredProducts = products.filter((product) => {
        if (!product?.productName.toLowerCase()) return false; // Ignore les produits sans nom

        const productName = product.productName.toLowerCase();
        const productFirstWord = getFirstWord(productName);

        return productCountry
            ? productFirstWord === baseWord && (productName.includes(productCountry))
            : productFirstWord === baseWord;
    });


    // Ajouter le produit sélectionné au début de la liste
    const options = [
        ...filteredProducts.map(product => ({ name: product.productName, id: product._id })),
    ];

    const othersProducts = options.filter(product => {
        return product.name.toLowerCase() !== productName.toLowerCase();
    });
    othersProducts.unshift({ name: productName, id: productId })

    const handleChange = (event) => {
        const selectedId = event.target.value; // Récupère l'ID sélectionné
        const selectedOption = options.find(option => option.id === selectedId); // Trouve l'option correspondante

        if (selectedOption) {
            setSelectedProductId(selectedId); // Met à jour l'ID sélectionné
            setSelectedProductName(selectedOption.name); // Met à jour le nom sélectionné
            setCurrentProduct(selectedId); // Passe l'ID au contexte
        }
    };

    return (
        <div className='content-products-dropdown'>

            <select
                className="products-dropdown"
                value={selectedProductId || ''} // Gère l'élément sélectionné
                onChange={handleChange}
            >
                {othersProducts.map((product, index) => (
                    <option key={index} value={product.id || ''} className="product-dropdown">
                        {product.name}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default DropDownProduct;
