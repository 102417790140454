import React, { useContext, useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../Assets/Imgs/icons/logo-senpremium2.svg';
import logoMobile from '../Assets/Imgs/icons/logo senpremium.png';
import { DataContext } from '../Services/DataContext';
import "../Assets/Styles/MainLayout.css";
import { decryptData } from '../Services/EncryptData';
import cart from "../Assets/Imgs/icons/mdi_cart.svg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGift, faUser } from '@fortawesome/free-solid-svg-icons';
import { Bell, CircleX, HousePlus, Menu, NotebookPen, UserRound } from 'lucide-react'
import { motion } from "framer-motion";
import netflix from "../Assets/Imgs/netflix.png"

function NavBar() {
  const { cartItems } = useContext(DataContext);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const location = useLocation();  // Pour déterminer l'URL actuelle
  const user = localStorage.getItem("user");
  const dataUser = user === null ? null : decryptData(user);
  // const [err, setErr] = useState(false)
  // const [loading, setLoading] = useState(true);

  // Affichage en cas de chargement ou d'erreur
  // if (loading) return <div>Chargement...</div>;
  // if (err) return <div>{err}</div>;


  console.log(location.pathname);

  return (
    <div className='nav-bar-parent' >
      <div className='nav-bar'>
        <div className='nav-bar-responsive'>
          <Link to='/'>
            <img src={logo} alt='logo' className='logo' />
            <img src={logoMobile} alt='logo' className='logo-mobile' />
          </Link>



          <div className='sous-menu'>
            <div className='cart-referral'>
              <Link
                to='/cart'
                className={`link-nav-bar nav-cart ${location.pathname === '/cart' ? 'active' : ''}`}
              // onClick={handleShowCart(true)}
              >
                <div id='length-items-cart'>
                  <span className='items-cart' > {cartItems.length} </span>
                </div>
                <img src={cart} alt="icon-conexion" />
              </Link>
              <Link to='/refer-a-friend' className='refer-a-friend-link'>
                <FontAwesomeIcon icon={faGift} className="icon-referral" />
                Parainner
              </Link>
            </div>
            {
              !isDropdownOpen ? (
                <div className='sous-menu-navigation' onClick={() => setIsDropdownOpen(true)} >
                  <Menu />
                </div>
              ) :
                <CircleX size={32} className='btn-close-dropdown-menu' onClick={() => setIsDropdownOpen(false)} />

            }
          </div>
        </div>

        <div className='connection-link'>
          <Link
            to='/'
            className={`link-nav-bar ${location.pathname === '/' ? 'active' : ''}`}
          >
            ACCUEIL
          </Link>
          <Link
            to='/products'
            className={`link-nav-bar ${((location.pathname.includes("/products")) || (location.pathname.includes("/subscription")) || (location.pathname.includes("/unknown-new-subscription"))) ? 'active' : ''}`}
          >
            ABONNEMENTS
          </Link>
          <Link
            to='/blog'
            className={`link-nav-bar ${location.pathname === '/blog' || (location.pathname.includes("/blog")) ? 'active' : ''}`}
          >
            BLOG
          </Link>
        </div>


        <div className='user-compte'>
          <div className='cart-referral'>
            <Link
              to='/cart'
              className={`link-nav-bar nav-cart ${location.pathname === '/cart' ? 'active' : ''}`}
            // onClick={handleShowCart(true)}
            >
              <div id='length-items-cart'>
                <span className='items-cart' > {cartItems.length} </span>
              </div>
              <img src={cart} alt="icon-conexion" />
            </Link>
            <Link to='/refer-a-friend' className='refer-a-friend-link'>
              <FontAwesomeIcon icon={faGift} className="icon-referral" />
              Parainner
            </Link>
          </div>
          <div className='connection'>
            {dataUser ? (
              <div className='dropdown'>
                <Link to="/account" id='user-name' >
                  <h3 id='user-first-name'> {dataUser?.firstName[0]} </h3>
                  <Menu />
                </Link>

              </div>
            ) : (
              <Link to='/signin' className='btn-connection'>
                <UserRound />
                Connexion
              </Link>
            )}
          </div>
        </div>

      </div>
      {/* NAvbar mobile */}



      {
        isDropdownOpen && (
          <>
            <div className='navbar-mobile'>
              <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: isDropdownOpen ? 1 : 0, y: isDropdownOpen ? 0 : -20 }}
                transition={{ duration: 0.3 }}
              >
                <div className='navbar-mobile-content'>
                  <Link
                    to='/'
                    className="navbar-mobile-link"
                  >
                    <HousePlus size={28} />
                    ACCUEIL
                  </Link>
                  <Link
                    to='/products'
                    className="navbar-mobile-link"
                  >
                    <Bell size={30} />
                    ABONNEMENTS
                  </Link>
                  <Link
                    to='/blog'
                    className="navbar-mobile-link"
                  >
                    <NotebookPen size={28} />
                    BLOG
                  </Link>
                  {
                    !dataUser && (
                      <div>
                        <Link to="/signin" className='btn-login'>
                          <FontAwesomeIcon icon={faUser} />
                          Connexion
                        </Link>
                      </div>
                    )
                  }
                  {
                    dataUser && (
                      <div>
                        <Link to="/account" id='user-name ' className='btn-login' >
                          <FontAwesomeIcon icon={faUser} />
                          <h3> {dataUser?.firstName} </h3>
                        </Link>
                      </div>
                    )
                  }
                </div>

              </motion.div>

            </div>

          </>
        )
      }
      {
        (location.pathname === "/" || location.pathname === "/products" || location.pathname.includes("products-category")) &&
        (<div className='alert-netflix'>
          <img src={netflix} alt="ne" style={{ width: "40px", height: '40px' }} />
          <span> <Link to="/subscription/662c4c3c7c6f78fa8e417300" style={{ textDecoration: "none", color: "white" }} >Netflix est de retour ! Profitez dès maintenant 🍿 → </Link> </span>
        </div>)
      }

    </div>
  );
}

export default NavBar;
