// import React, { useContext, useEffect } from "react";
import React, { useEffect } from "react";

import AboutUs from "../Components/Home/AboutUs";
import Footer from "../Layouts/Footer";
import "../Assets/Styles/Home.css"
import SubShare from "../Components/Home/SubShare";
import NavBar from "../Layouts/NavBar";
import { decryptData } from "../Services/EncryptData";
import CustomListLayout from "../Layouts/CustomListLayout";
import listElementsScrolling from '../Components/Home/ListElementsScrolling';
import moreFrequently from "../Assets/Imgs/icons/mostFrequently.svg"
import HowToWork2 from "../Components/Home/HowToWork2";
import GeneralBanner from "../Components/Home/GeneralBanner";

// import CountDownTimer from "../Layouts/CountDownTimer";
// import { DataContext } from "../Services/DataContext";
// import axios from "axios";
// import Banner from "../Components/Home/Banner";
// import HowItWorks from "../Components/Home/HowItWorks";

function Home() {

  const _user = localStorage.getItem('user');
  const user = _user === null ? null : decryptData(_user)
  // const { products } = useContext(DataContext)

  // console.log(user);
  user ?
    window.Intercom("boot", {
      api_base: "https://api-iam.intercom.io",
      app_id: "acvunhpb",
      name: `${user.firstName} ${user.lastName}`, // Nom complet
      email: user.email, // l’e-mail de votre utilisateur
      created_at: user.createdAt, // Date d’inscription en tant qu’horodatage Unix
    })
    :
    window.Intercom("boot", {
      api_base: "https://api-iam.intercom.io",
      app_id: "acvunhpb",
      name: "Boss", // Nom complet
    })
  window.Intercom("update");
  window.scrollTo(0, 0);

  // Vérifie l'expiration du token lors du chargement initial du composant
  useEffect(() => {
    checkTokenExpiration();
  }, []);

  // Fonction pour vérifier l'expiration du token
  const checkTokenExpiration = () => {
    const tokenExpiration = localStorage.getItem('tokenExpiration');
    if (tokenExpiration) {
      const expirationTime = parseInt(tokenExpiration);
      const currentTime = new Date().getTime();
      if (currentTime > expirationTime) {
        // Supprimer le token et déconnecter l'utilisateur
        localStorage.removeItem('token');
        localStorage.removeItem('tokenExpiration');
        localStorage.removeItem('user');
      }
    }
  };

  // Fonction pour mélanger le tableau de manière aléatoire
  function shuffleArray(array) {
    return array.sort(() => Math.random() - 0.5);
  }

  // Mélanger les éléments de listElementsScrolling
  const shuffledListElements = shuffleArray(listElementsScrolling);

  // const productsInPromo = products.filter(product => product.promo?.InPromo === true)
  // // console.log(productsInPromo);

  // const topPromo = [
  //   "Apple iTunes 10€ (France)",
  //   "PlayStation Network (PSN) 10€",
  //   "Pack Solo Netflix & Prime, 1 écran",
  //   "Pack Disney+ x Prime Video x VPN Premium 1Mois"
  // ];

  //faire la mm chose avec music
  // const priorityPrmo = productsInPromo.filter(product => topPromo.includes(product.productName));
  // const othersProductsInPromo = productsInPromo.filter(product => !topPromo.includes(product.productName));
  // // const shuffledOthers = shuffleArray(others);
  // const newArrayProductsInPromo = ([...priorityPrmo, ...othersProductsInPromo]);

  return (
    <div className="home">
      <NavBar />
      <GeneralBanner />

      <div className="more-freq">
        <CustomListLayout
          listItems={shuffledListElements}
          typeName="Pour Vous"
          iconType={moreFrequently}
        />
      </div>
      <SubShare />
      <HowToWork2 />
      <AboutUs />
      <Footer />
    </div>
  )
}

export default Home