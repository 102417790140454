/* eslint-disable no-undef */
import React, { useContext, useEffect, useState } from 'react';
import config from "../Services/config.js";
import Instance from '../Services/Instance';
import { DataContext } from '../Services/DataContext.js';
import NavBar from '../Layouts/NavBar.js';
import levels from "../Assets/Imgs/icons/icon-levels-subscriptions.svg";
import { Link } from 'react-router-dom';
import deleteProduct from "../Assets/Imgs/icons/icon-delet-item.svg";
import arrowIcon from "../Assets/Imgs/icons/right-arrow-circular-symbol-svgrepo-com.svg";
import Modal from 'react-modal';
import "../Assets/Styles/Cart.css"
import { decryptData } from '../Services/EncryptData.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faGift, faRightToBracket, faTicket } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import loadingImg from "../Assets/Imgs/icons/loading2.gif"



const Cart = () => {
    const { cartItems, removeFromCart, updateQuantity } = useContext(DataContext);
    const [detailedCartItems, setDetailedCartItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [paymentInProgress, setPaymentInProgress] = useState(false);
    const [codePromo, setCodePromo] = useState("");
    const [applyCodePromo, setApplyCodePromo] = useState(false);
    const [promoApplied, setPromoApplied] = useState(false);
    const [totalAmount, setTotalAmount] = useState(0)

    const [userReferrals, setUserReferrals] = useState(0)
    const [newUserReferrals, setNewUserReferrals] = useState(0)

    const [discountedItems, setDiscountedItems] = useState([]); // Stocke les articles avec des remises
    const [reNewal, setReNewal] = useState(false);
    const [applyDiscountedPricePoints, setApplyDiscountedPricePoints] = useState(false)
    const [pointsApplied, setPointsApplied] = useState(0);
    const [discountedPricePoints, setDiscountedPricePoints] = useState(false);

    const [newSubscription, setNewSubscription] = useState({
        userFirstName: "",
        userLastName: "",
        userEmail: "",
        userNumberPhone: "",
        userCountry: "",
        confirmEmail: ""
    });

    // Récupération de l'utilisateur depuis le stockage local
    const user__ = localStorage.getItem("user")
    const user = user__ === null ? null : decryptData(user__)


    // Récupération des données du local storage pour smartTv et netflix
    const smarTvPrice = localStorage.getItem('smartTvPrice');
    const netflixType = localStorage.getItem("netflixType");


    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewSubscription({ ...newSubscription, [name]: value });
    };

    // Affiche le champ de code promo
    const ActiveDiscount = (type) => {

        if (type === "promo") {
            setApplyCodePromo((prevState) => !prevState);
        } else if (type === "points") {
            if (!user) {
                alert("Vous devez vous connectez pour appliquer vos points")
                return;
            }
            setApplyDiscountedPricePoints((prevState) => !prevState);
        }

    };

    let newPromoValue;
    const handleChangeValue = (e, type) => {
        if (type === "promo") {
            newPromoValue = e.target.value;
        }
        if (type === "points") {
            setPointsApplied(e.target.value)
        }

    }
    useEffect(() => {
        const fetchDataReferrals = async () => {
            try {
                const res = await Instance.get(`${config.api_url}/referrals-pending/${user._id}`);
                const response = await Instance.get(`${config.api_url}/referrals-completed/${user._id}`);

                if (res.data?.referrals?.length !== 0) {
                    setNewUserReferrals(0.05); //S'il y a des referrals on recupére directement la reduction (ici 5% on s'attend à manipuler directement cette valeur avec celle de la totale
                }
                if (response.data?.referrals?.length !== 0) {
                    setUserReferrals(0.05 * response.data?.referrals?.length);
                }
            } catch (error) {
                setError(error);
            }
        };

        fetchDataReferrals();
    }, []);

    // Fonction pour récupérer les détails des produits à partir de l'API
    const fetchProductDetails = async () => {
        try {
            const productPromises = cartItems.map(async (item) => {
                const result = await Instance.get(`${config.api_url}/show-product/${item.productId}`);

                // Ajuster le prix en fonction des données du localStorage
                let priceSub = result.data.product.productPrice;
                let optionSub = "";

                if (smarTvPrice
                    && result.data.product.productName.toLowerCase().includes("netflix")
                    && !result.data.product.productName.includes("NETFLIX Pack Family")
                    && !result.data.product.productName.includes("Netflix Duo")
                ) {
                    priceSub = smarTvPrice;
                }

                if (netflixType
                    && result.data.product.productName.toLowerCase().includes("netflix")
                    && !result.data.product.productName.includes("NETFLIX Pack Family")
                    && !result.data.product.productName.includes("Netflix Duo")

                ) {
                    optionSub = `- ${netflixType}`;
                } else if (!netflixType && result.data.product.productName.toLowerCase().includes("netflix")
                    && !result.data.product.productName.includes("NETFLIX Pack Family")
                    && !result.data.product.productName.includes("Netflix Duo")) {
                    optionSub = "- Phone/Pc";
                }

                return {
                    ...result.data.product,
                    priceSub: item.promo?.InPromo ? item.promo.productPricePromo : priceSub,
                    optionSub,
                    quantity: item.quantity,
                    totalItemPrice: item.promo?.InPromo ? priceSub * item.promo.productPricePromo : priceSub * item.quantity
                };
            });

            const products = await Promise.all(productPromises);
            setDetailedCartItems(products);
        } catch (err) {
            setError('Erreur lors de la récupération des produits.');
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        if (cartItems.length > 0) {
            fetchProductDetails();
        } else {
            setDetailedCartItems([]);
            setLoading(false);
        }
    }, [cartItems]);

    // Calculer le prix total du panier sans reduction
    const calculateTotalPrice = () => {
        return detailedCartItems.reduce((total, item) => {
            return total + item.totalItemPrice;
        }, 0);
    };
    useEffect(() => {

        // Calculer le prix total du panier avec les promos
        const calculateTotalPriceWithPromo = () => {
            return discountedItems.reduce((total, item) => {
                const totalPrice = total + item.totalItemPrice;
                return totalPrice

            }, 0);
        };

        const amount = calculateTotalPriceWithPromo();
        setTotalAmount(amount)
    }, [discountedItems]);


    // Fonction pour appliquer le code promo
    const applyPromoCode = () => {
        let promoValid = false;
        const updatedItems = detailedCartItems.map(item => {
            if (item && item.promo?.InPromo && (item.promo?.codePromo === newPromoValue)) {
                setCodePromo(newPromoValue)
                setPromoApplied(true)
                promoValid = true;

                return {
                    ...item,
                    totalItemPrice: item.promo.productPricePromo * item.quantity,
                    discountedPrice: item.promo.productPricePromo
                };
            }
            return item;
        });

        if (promoValid) {
            setDiscountedItems(updatedItems); // Mettre à jour les articles avec les réductions
            toast.success('😁 Code Promo Appliqué avec succès !!!');
        } else {
            toast.error('🙄 Code Promo Invalide !!!');
        }
    };


    // Incrémentation de la quantité
    const increment = (productId) => {
        const item = cartItems.find(item => item.productId === productId);
        updateQuantity(productId, item.quantity + 1);
    };

    // Décrémentation de la quantité
    const decrement = (productId) => {
        const item = cartItems.find(item => item.productId === productId);
        if (item && item.quantity > 1) {
            updateQuantity(productId, item.quantity - 1);
        }
    };

    // Supprimer le produit du panier
    const handleRemove = (productId) => {
        removeFromCart(productId);
    };

    //Apply points
    const applyDiscountPoints = async () => {
        const result = await Instance.get(`${config.api_url}/show-user/${user._id}`)
        const userPoints = result.data.user.point;
        const priceSub = calculateTotalPrice();
        if (userPoints >= pointsApplied) {
            const discountPrice = pointsApplied;

            // Vérification pour empêcher d'appliquer Des points SUPP AU PRIX
            if (discountPrice > priceSub - 100) {
                toast.error(`Le maximum de points que vous pouvez appliquer pour cette commande est de ${priceSub - 100}`);
            } else {
                setDiscountedPricePoints(true)
                toast.success(`Vous avez activez vos points pour Bénéficier de ${discountPrice}cfa réduction`)
            }
        } else {
            toast.error('🙄 Vous ne disposez pas de ce nombre de points !!!');
        }
    }
    const { userFirstName, userLastName, userEmail, confirmEmail, userNumberPhone, userCountry } = newSubscription;


    const winPoints = () => {
        let points
        let totalPrice = calculateTotalPrice()
        if (totalPrice > 25000) {
            points = totalPrice / 80
        } else {
            points = totalPrice / 40
        }
        return points;
    }

    const handleSubscriptionRequest = async () => {
        if (paymentInProgress) return; // Si la demande de paiement est déjà en cours, ne rien faire

        try {
            let order = [];
            detailedCartItems.map(async (orderProduct,index) => {

                let optionSub = "";

                // console.log(netflixType);

                if (netflixType
                    && orderProduct.productName.toLowerCase().includes("netflix")
                    && !orderProduct.productName.includes("NETFLIX Pack Family")
                    && !orderProduct.productName.includes("Netflix Duo")
                ) {
                    optionSub = `- ${netflixType}`;
                } else if (!netflixType && orderProduct.productName.toLowerCase().includes("netflix")
                    && !orderProduct.productName.includes("NETFLIX Pack Family")
                    && !orderProduct.productName.includes("Netflix Duo")) {
                    optionSub = "- Phone/Pc";
                }

                let orderRequestSubscription = {}

                if (user) {
                    orderRequestSubscription = {
                        productTypeSmart: optionSub,
                        productId: orderProduct._id,
                        subscriptionQuantity: orderProduct.quantity,
                        subPoints: [winPoints(), pointsApplied],
                        codePromo: codePromo,
                        userId: user._id,
                    }
                } else {
                    orderRequestSubscription = {
                        productTypeSmart: optionSub,
                        productId: orderProduct._id,
                        subscriptionQuantity: orderProduct.quantity,
                        subPoints: [winPoints(), pointsApplied],
                        codePromo: codePromo,
                        userFirstName: userFirstName,
                        userLastName: userLastName,
                        userEmail: userEmail,
                        userNumberPhone: userNumberPhone,
                        userCountry: userCountry || 'Senegal',
                    };
                }

                order.push(orderRequestSubscription)

            })
            setPaymentInProgress(true);
            const formattedData = { subscriptions: JSON.stringify(order) };

            console.log("========" , formattedData);
            

            (new PayTech(formattedData)).withOption({
                // requestTokenUrl: Instance.post(`${config.api_url}/new-subscription`),
                requestTokenUrl: (`${config.api_url}/new-subscription`),

                method: 'POST',
                headers: {
                    // "Accept": "text/html",
                    Accept: "application/json",

                },
                prensentationMode: PayTech.OPEN_IN_POPUP,
                willGetToken: function () {

                },
                didGetToken: function (token, redirectUrl) {

                },
                didReceiveError: function (error) {

                },
                didReceiveNonSuccessResponse: function (jsonResponse) {

                }
            }).send();

        } catch (error) {
            console.log("Erorrrrrrrrrrrrr" , error);
            
            console.error('Erreur lors de la demande d\'abonnement :', error);
        } finally {
            setPaymentInProgress(false); // Réinitialiser l'état de la demande de paiement
        }
    };

    // Demander à l'utilisateur s'il va faire un renouvellement ou pas
    const handleReNewal = () => { setReNewal(!reNewal) };

    // Affichage en cas de chargement ou d'erreur
    if (loading) {
        return (
            <div style={{ width: "100vw", height: "100vh", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
                <p>Produits en Chargement</p>
                <img src={loadingImg} alt="" />
            </div>
        )
    }
    return (
        <div className='cart'>
            <NavBar />

            <div className='cart-content'>
                <div className='subscription-content-layout subscription-levels'>
                    <img src={levels} alt="delivery-img" />
                </div>
                <div className='sous-navbar '>
                    <Link to="/" className='link-sous-navbar'> Home </Link>
                    <span>/</span>
                    <Link to="/products" className='link-sous-navbar'> Abonnements </Link>
                    <span>/</span>
                    <Link to="/cart" className='link-sous-navbar' id='activeLink'> Panier </Link>
                </div>

                <div className='cart-container '>
                    <div className='cart-container-left '>
                        <h3 className='length-cart'>Articles de votre panier : <span> {detailedCartItems.length} </span></h3>
                        {detailedCartItems.length === 0 ? (
                            <div className='content-cart-amphy'>
                                <p>Votre panier est vide.</p>
                                <p>Il semblerait que vous n'avez encore rien ajouté à votre panier</p>
                                <Link to="/products" className='btn-show-cart' id='btn-view-products-cart'>Parcourir les produits</Link>
                            </div>
                        ) : (
                            <ul>
                                {detailedCartItems.map((item) => (
                                    <li key={item._id} className='container-product-cart'>
                                        <div className='details-product-cart details-content-cart'>
                                            <img src={`${config.img_url}/${item.productImage}`} alt='img-product' />
                                            <div className='product-cart-infos'>
                                                <div className='product-cart-name'>
                                                    <h3> {item.productName} {item.optionSub} </h3>
                                                    {/* {item.promo?.InPromo === true ? ( */}
                                                    {(item && item.promo?.InPromo && (item.promo?.codePromo === codePromo)) ? (
                                                        <div className='product-prices'>
                                                            <h3 className='original-price' style={{ textDecoration: 'line-through red', color: "gray" }}>
                                                                {item.productPrice} F CFA
                                                            </h3>
                                                            <h3 className='discounted-price'>
                                                                {item.promo?.productPricePromo} F CFA
                                                            </h3>
                                                        </div>
                                                    ) : (
                                                        <h3> {item.priceSub} F CFA </h3>
                                                    )}
                                                </div>
                                                <div className='product-cart-quantity'>
                                                    <p>Quantité</p>
                                                    <div className='counter-cart'>
                                                        <button type="button" onClick={() => decrement(item._id)} className='counter-cart'>-</button>
                                                        <button type="button" className='counter-cart' id='quantity-cart'>{item.quantity}</button>
                                                        <button type="button" onClick={() => increment(item._id)} className='counter-cart'>+</button>
                                                    </div>
                                                </div>
                                                <div className='product-total-price'>
                                                    <p>Total :</p>
                                                </div>
                                                {(item && item.promo?.InPromo && (item.promo?.codePromo === codePromo)) ? (

                                                    <p>{item.quantity * item.promo.productPricePromo} F CFA</p>
                                                ) : (
                                                    <p>{item.quantity * item.productPrice} F CFA</p>
                                                )
                                                }

                                            </div>
                                        </div>
                                        <img src={deleteProduct} alt="deleteProduct" className='deleteProduct' onClick={() => handleRemove(item._id)} />
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                    <div className='cart-container-right subscription-content-layout' id={cartItems.length === 0 ? "cart-amphy-style" : ""}>

                        <div className='promo-code' id='apply-promo' onClick={() => ActiveDiscount("points")}>
                            <div className='details-promo-code' >
                                <FontAwesomeIcon icon={faGift} className='coupon-icon' />
                                <h4 > Appliquer Mes points</h4>
                            </div>
                            <h3  >{`>`}</h3>
                        </div>

                        {
                            applyDiscountedPricePoints &&
                            (<div className='add-promo-code'>
                                <input type="number" placeholder='points' onChange={(e) => handleChangeValue(e, "points")} />
                                <button onClick={() => applyDiscountPoints()}>Appliquer</button>

                            </div>)
                        }
                        <div className='promo-code' onClick={() => ActiveDiscount("promo")}>
                            <div className='details-promo-code'>
                                <FontAwesomeIcon icon={faTicket} className='coupon-icon' />
                                <h4 > Appliquer un code promo</h4>
                            </div>
                            <h3>{`>`}</h3>
                        </div>
                        {
                            applyCodePromo &&
                            (<div className='add-promo-code'>
                                <input type="text" placeholder='Code' onChange={(e) => handleChangeValue(e, "promo")} />
                                <button onClick={() => { applyPromoCode() }}>Appliquer</button>

                            </div>)
                        }

                        <hr />
                        <h3>Détails de paiement</h3>
                        <div className='points-win'>
                            <span>Points gagné :</span>
                            <div className='points-win-details'>
                                <FontAwesomeIcon icon={faGift} className='iocn-win' />
                                <h4> {winPoints()} </h4>
                            </div>
                        </div>
                        <div className='subscription-options-details sub-total'>
                            <p>Sub Total </p>

                            {/* {
                                (promoApplied && !discountedPricePoints) ?
                                    (
                                        <div>
                                            <h3> {totalAmount - (totalAmount * userReferrals) - (totalAmount * newUserReferrals)} F CFA</h3>
                                            <h3 style={{ textDecoration: 'line-through red', color: "gray" }} > {calculateTotalPrice()} F CFA</h3>

                                        </div>

                                    )
                                    : (promoApplied && discountedPricePoints) ?
                                        (<div>
                                            <h3 className='total-price'> {(totalAmount - pointsApplied) - ((totalAmount - pointsApplied) * userReferrals) - ((totalAmount - pointsApplied) * newUserReferrals)} F CFA</h3>
                                            <h3 style={{ textDecoration: 'line-through red', color: "gray" }} > {calculateTotalPrice()} F CFA</h3>

                                        </div>)
                                        : discountedPricePoints ?
                                            (<div>
                                                <h3> {(calculateTotalPrice() - pointsApplied) - ((calculateTotalPrice() - pointsApplied) * userReferrals) - ((calculateTotalPrice() - pointsApplied) * newUserReferrals)} F CFA</h3>
                                                <h3 style={{ textDecoration: 'line-through red', color: "gray" }} > {calculateTotalPrice()} F CFA</h3>

                                            </div>) : (newUserReferrals !== 0) || (userReferrals !== 0) ?
                                                (
                                                    <div>
                                                        <h3> {calculateTotalPrice() - (calculateTotalPrice() * userReferrals) - (calculateTotalPrice() * newUserReferrals)} F CFA</h3>
                                                        <h3 style={{ textDecoration: 'line-through red', color: "gray" }} > {calculateTotalPrice()} F CFA</h3>

                                                    </div>

                                                )
                                                :
                                                <h3> {calculateTotalPrice()} F CFA</h3>

                            } */}
                            <h3> {calculateTotalPrice()} F CFA</h3>

                        </div>
                        <div className='subscription-options-details frais-payment'>
                            <p>Frais Paiement </p>
                            <h3> +2%</h3>
                        </div>

                        {((userReferrals !== 0)) &&
                            <div className='subscription-options-details frais-payment' >
                                <p>  Réducton de Parrainage</p>
                                <h3 className='parrainage-reduction'> {100 * userReferrals}% OFF </h3>
                            </div>}

                        {((newUserReferrals !== 0)) &&
                            <div className='subscription-options-details frais-payment' >
                                <p>  Réducton de Parrainage</p>
                                <h3 className='parrainage-reduction'> 5% OFF </h3>
                            </div>}

                        {
                            discountedPricePoints && (
                                <div className='subscription-options-details frais-payment' >
                                    <p>  Points Appliqués</p>
                                    <h3 className='parrainage-reduction'> {pointsApplied}cfa OFF </h3>
                                </div>
                            )
                        }

                        {
                            promoApplied && (
                                <div className='subscription-options-details frais-payment' >
                                    <p>Code Promo</p>
                                    <h3 className='parrainage-reduction'>  Appliqué</h3>
                                </div>
                            )
                        }


                        <div className='subscription-options-details sub-total'>
                            <p>Total du panier : </p>
                            {
                                (promoApplied && !discountedPricePoints) ?
                                    (
                                        <div>
                                            <h3> {(totalAmount - (totalAmount * userReferrals) - (totalAmount * newUserReferrals)) + ((totalAmount - (totalAmount * userReferrals) - (totalAmount * newUserReferrals)) * 0.02)} F CFA</h3>
                                            <h3 style={{ textDecoration: 'line-through red', color: "gray" }} > {calculateTotalPrice() + (calculateTotalPrice() * 0.02)} F CFA</h3>

                                        </div>

                                    )
                                    : (promoApplied && discountedPricePoints) ?
                                        (<div>
                                            <h3 className='total-price'> {((totalAmount - pointsApplied) - ((totalAmount - pointsApplied) * userReferrals) - ((totalAmount - pointsApplied) * newUserReferrals)) + (((totalAmount - pointsApplied) - ((totalAmount - pointsApplied) * userReferrals) - ((totalAmount - pointsApplied) * newUserReferrals)) * 0.02)} F CFA</h3>
                                            <h3 style={{ textDecoration: 'line-through red', color: "gray" }} > {calculateTotalPrice() + (calculateTotalPrice() * 0.02)} F CFA</h3>

                                        </div>)
                                        : discountedPricePoints ?
                                            (<div>
                                                <h3> {((calculateTotalPrice() - pointsApplied) - ((calculateTotalPrice() - pointsApplied) * userReferrals) - ((calculateTotalPrice() - pointsApplied) * newUserReferrals)) + (((calculateTotalPrice() - pointsApplied) - ((calculateTotalPrice() - pointsApplied) * userReferrals) - ((calculateTotalPrice() - pointsApplied) * newUserReferrals)) * 0.02)} F CFA</h3>
                                                <h3 style={{ textDecoration: 'line-through red', color: "gray" }} > {calculateTotalPrice() + (calculateTotalPrice() * 0.02)} F CFA</h3>

                                            </div>) : (newUserReferrals !== 0) || (userReferrals !== 0) ?
                                                (
                                                    <div>
                                                        <h3> {(calculateTotalPrice() - (calculateTotalPrice() * userReferrals) - (calculateTotalPrice() * newUserReferrals)) + ((calculateTotalPrice() - (calculateTotalPrice() * userReferrals) - (calculateTotalPrice() * newUserReferrals)) * 0.02)} F CFA</h3>
                                                        <h3 style={{ textDecoration: 'line-through red', color: "gray" }} > {calculateTotalPrice() + (calculateTotalPrice() * 0.02)} F CFA</h3>

                                                    </div>

                                                )
                                                :
                                                <h3> {calculateTotalPrice() + (calculateTotalPrice() * 0.02)} F CFA</h3>

                            }
                        </div>
                        {
                            user &&
                            (<button onClick={() => { handleSubscriptionRequest() }} className='validate-subscription-btn' id='btn-payer'>
                                {paymentInProgress ? 'Traitement en cours...' : 'Payer'}
                            </button>)
                        }
                    </div>
                </div>

                {
                    !user && detailedCartItems.length !== 0 &&
                    (
                        <div className='subscription-content-layout content-left-unknownSub form-cart-uknow'>
                            <form onSubmit={(e) => {
                                e.preventDefault(); // Empêche le rechargement de la page
                                handleSubscriptionRequest();
                            }}>
                                <div className='options-subscription'>
                                    <div type="button" onClick={handleReNewal} className='options-subscription-button'>
                                        <span>Souhaitez-vous faire <br /> un renouvellement ?</span>
                                        <img className='img-arrow' src={arrowIcon} alt="arrow" />
                                    </div>
                                    <Link to="/signin" className=''>Vous avez déjà un compte? Se connecter</Link>
                                </div>
                                <div className='elements-from'>
                                    <div>
                                        <label>Prénom</label>
                                        <input
                                            name="userFirstName"
                                            value={userFirstName}
                                            type="text"
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label>Nom</label>
                                        <input
                                            name="userLastName"
                                            value={userLastName}
                                            onChange={handleChange}
                                            type="text"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label>Pays</label>
                                        <input
                                            name="userCountry"
                                            value={userCountry}
                                            type="text"
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className='elements-from'>

                                    <div>
                                        <label>Téléphone (Whatsapp)</label>
                                        <input
                                            name="userNumberPhone"
                                            value={userNumberPhone}
                                            type="text"
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label>Email</label>
                                        <input
                                            name="userEmail"
                                            value={userEmail}
                                            type="email"
                                            pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                                            onChange={handleChange}
                                            required
                                        />

                                    </div>
                                    <div>
                                        <label>Confirmez votre Email</label>
                                        <input
                                            name="confirmEmail"
                                            value={confirmEmail}
                                            type="email"
                                            pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                                            onChange={handleChange}
                                            required
                                        />
                                        {
                                            newSubscription.confirmEmail && newSubscription.userEmail !== newSubscription.confirmEmail &&
                                            (
                                                <h6 id='confirm-email'>Vous avez renseigné des emails différents</h6>
                                            )
                                        }
                                    </div>

                                </div>

                                <div id='cgu'>
                                    <div className='checkbox'>
                                        <input
                                            id='checkbox'
                                            name="CGU"
                                            type="checkbox"
                                            required
                                        />
                                        <label>J’ai lu et j’accepte les <Link to="/help-center/CGU">CGU</Link> de Senpremium. </label>
                                    </div>
                                </div>
                                <button className='validate-subscription-btn' id='btn-payer'>
                                    {paymentInProgress ? 'Traitement en cours...' : 'Payer'}
                                </button>
                            </form>
                        </div>

                    )
                }

            </div>
            <Modal
                isOpen={reNewal}
                onRequestClose={handleReNewal}
                contentLabel="Réabonnement Modal"
                className="modal"
                overlayClassName="overlay"
            >
                <div className='wish-renewal'>
                    <div className='wish-renewal-title'>
                        <h2>Faire un réabonnement avec Senpremium.</h2>

                    </div>
                    <div className='wish-renewal-details'>
                        <div>
                            <h3> 1. Connexion/Inscription</h3>
                            <p>Cliquez sur "Connexion" pour vous connecter ou créer un compte.</p>
                        </div>

                        <div>
                            <h3> 2. Mes Abonnements</h3>
                            <p>Allez dans "Mes Abonnements" via "Compte" dans la barre de navigation.                            </p>
                        </div>
                        <div>
                            <h3> 3. Retrouver vos abonnements</h3>
                            <p>Consultez tous vos abonnements antérieurs, même sans compte</p>
                        </div>
                        <div>
                            <h3> 4. Effectuer votre réabonnement</h3>
                            <p>Sélectionnez et réabonnez-vous.</p>
                        </div>
                    </div>
                    <div className='wish-renewal-old'>
                        <div className='line'></div>
                        <div>
                            <h3>Pour les anciens utilisateurs de Senpremium :</h3>
                            <p>Pour conserver vos anciens accès, connectez-vous et recherchez l'abonnement correspondant. Fournissez les informations de votre commande et les anciens accès sur la page de paiement.</p>

                        </div>
                    </div>
                    <div className='wish-renewal-btn'>
                        <button onClick={handleReNewal} className='link-btn-wish link-btn-close'> <FontAwesomeIcon icon={faCircleXmark} /> <span>Fermer</span> </button>
                        <Link to="/signin" className='link-btn-wish'><FontAwesomeIcon icon={faRightToBracket} /> <span>Se Connecter</span> </Link>
                    </div>
                </div>
            </Modal>

        </div >
    );
};

export default Cart;
